import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import API_URL from "../config";
import Grid from "../components/Grid";
import { ROLES } from "../constants/Common";
import AuthContext from "../store/auth-context";
import { DisplayResults, GET } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Users.css";

const UserList = () => {
  const authCtx = useContext(AuthContext);
  const [gridData, setGridData] = useState(null);

  const getLocationText = (id) => {
    if (id === 1) {
      return "All Customer Locations";
    } else if (id === 2) {
      return "Default Kitchen Locations";
    } else if (id === 3) {
      return "Specific Locations";
    }
  };

  const getDataCallback = (response) => {
    var temp = [];

    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          Id: d.id,
          User: d.name,
          Role: ROLES.find((r) => r.id === d.roleId).name,
          "Default Production Kitchen": d.kitchen.name,
          "Customer Locations": getLocationText(d.locationPermissionId),
        };
        temp.push(u);
      });
    }
    
    setGridData(temp);
  };

  const userCellRenderer = (e) => {
    var userlink = "/users/" + e.data.Id;
    return (
      <Link className="user-link" to={userlink}>
        {e.data.User}
      </Link>
    );
  };

  const [columnDefs] = useState([
    { field: "User", flex: 1, cellRenderer: userCellRenderer },
    { field: "Role", flex: 1 },
    { field: "Default Production Kitchen", flex: 1 },
    { field: "Customer Locations", flex: 1 },
  ]);

  const fetchUsersHandler = useCallback(async () => {
    try {
      GET(`${API_URL}/api/v1/User/GetAllUsers`, {}, getDataCallback);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchUsersHandler();
  }, [fetchUsersHandler]);

  return (
    <div className="root-block">
      <Row style={{ float: "right" }}>
        {authCtx.writeRights.includes("UserList") &&
        <Link className="user-link" to="/users/add">
          + Add User
        </Link>}
      </Row>
      <Grid
        sortable={true}
        filter={true}
        data={gridData}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default UserList;
