import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FilterToSundays } from "../services/DateHelper"
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import API_URL from "../config";
import { ShowToast } from "../services/Toast";
import AuthContext from "../store/auth-context";
import { TOAST_MODE } from "../constants/Common";
import { COPY_TO_SUCCESS } from "../constants/Messages";
import { PRICE_LIST_EDITOR } from "../constants/Messages";
import { EnterEditMode, NavigateKeyDown } from "../services/GridHelper"
import { DELETE, DisplayResults, GET, POST, PUT } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./PriceList.css";
import "../components/Grid.css";

const PriceDetails = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const name = useRef("");
  const [copyDialogVisibility, setCopyDialogVisibility] = useState(false);
  const [removeDialogVisibility, setRemoveDialogVisibility] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [activeDate, setActiveDate] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [priceListData, setPriceListData] = useState(null);
  const [fullRevisionList, setFullRevisionList] = useState([]);
  const [copyToTargets, setCopyToTargets] = useState([]);
  const [tempRevisions, settempRevisions] = useState([]);
  const [revisions, setRevisions] = useState([]);
  const [needsProductRefresh, setNeedsProductRefresh] = useState(false);

  const priceRevision = useRef();
  const creditDays = useRef();
  const params = useParams();
  const copyCost = useRef();
  const copyRetailPrices = useRef();
  const copyWholeSalePrices = useRef();

  //Grid Related information
  const gridRef = useRef();
  const [gridData, setGridData] = useState([]);
  const gridOptions = {
    localeText: {
      noRowsToShow:
        "There is no data existing. Please create a new order or choose copy to copy from the previous week",
      loadingOoo: "Loading data, please wait...",
    },
    rowClassRules: {
      "custom-row-style": "data.length === 0", // apply custom class to rows with no data
    },
  };
  const defaultColDef = {
    sortable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([
    { field: "Product", flex: 2, editable: false },
    {
      field: "Cost",
      flex: 1,
      valueFormatter: (params) => {
        return params.value == null
          ? ""
          : "$" + Number(params.value).toFixed(2);
      },
      editable: true,
    },
    {
      field: "Retail Price",
      flex: 1,
      valueFormatter: (params) => {
        return params.value == null
          ? ""
          : "$" + Number(params.value).toFixed(2);
      },
      editable: true,
    },
    {
      field: "Wholesale Price",
      flex: 1,
      valueFormatter: (params) => {
        return params.value == null
          ? ""
          : "$" + Number(params.value).toFixed(2);
      },
      editable: true,
    },
  ]);

  const saveHandler = () => {
    gridRef.current.api.stopEditing();
    validateCells();

    if (name.current.value.trim() == "") {
      ShowToast(TOAST_MODE.ERROR, "Please enter the name of the price list");
      return;
    }

    var data = priceListData;
    data.priceList.name = name.current.value;
    data.priceList.creditDays = creditDays.current.value;
    data.priceList.renewalDate = renewalDate;
    data.activeDate = activeDate;
    data.priceListRevisionId = priceRevision.current.value;
    data.revisionName = priceRevision.current.selectedOptions[0].text;
    var priceData = [];
    gridData.forEach((d) => {
      priceData.push({
        id: d.Id,
        sequence: d.Sequence,
        name: d.Product,
        productId: d.ProductId,
        retailPrice: d["Retail Price"],
        wholesalePrice: d["Wholesale Price"],
        cost: d.Cost,
      });
      data.productPrices = priceData;
    });

    PUT(
      `${API_URL}/api/v1/Prices/UpdatePriceListRevision`,
      data,
      (response) => {
        DisplayResults(response, "Price list revision updated");
      }
    );
  };

  const getPriceCallback = (response) => {
    setShowLoader(false);
    
    if (DisplayResults(response)) {
      var data = response.result;
      name.current.value = data.priceList.name;
      creditDays.current.value = data.priceList.creditDays;

      var temp = [];
      data.productPrices.forEach((d) => {
        var u = {
          Id: d.id,
          Product: d.name,
          Cost: d.cost,
          ProductId: d.productId,
          Sequence: d.sequence,
          "Retail Price": d.retailPrice,
          "Wholesale Price": d.wholesalePrice,
        };
        temp.push(u);
      });
      setRevisions(data.priceListRevisions);
      setGridData(temp);
      setPriceListData(data);
      setActiveDate(new Date(data.activeDate));
      setNeedsProductRefresh(data.needsRefresh);
      if (data.priceList.renewalDate == null) setRenewalDate(null);
      else setRenewalDate(new Date(data.priceList.renewalDate));
    }
  };

  const fetchPriceDetails = (id, revisionId) => {
    setShowLoader(true);
    try {
      GET(
        `${API_URL}/api/v1/Prices/GetPriceListRevisionDetails?id=` +
          id +
          "&revisionId=" +
          revisionId,
        {},
        getPriceCallback
      );
    } catch (err) {
      console.log(err);
    }
  };

  const showCopyDialog = () => {
    var otherRevisions = [...fullRevisionList];
    var selectedRevisionIndex = otherRevisions.findIndex(function(revision) { return revision.id == priceRevision.current.value });

    if (selectedRevisionIndex !== -1)
      otherRevisions.splice(selectedRevisionIndex, 1);
    setCopyToTargets(otherRevisions);

    setCopyDialogVisibility(true);
  };

  const handleClose = () => {
    setCopyDialogVisibility(false);
  };

  const showRemoveDialog = () => {
    setRemoveDialogVisibility(true);
  };

  const handleCloseRemoveDialog = () => {
    setRemoveDialogVisibility(false);
  };

  const revisionList =
    revisions.length > 0 &&
    revisions.map((item, i) => {
      return (
        <option
          key={i + item.name}
          value={item.id}
          data-keyid={item.name}
          selected={
            priceListData && priceListData.priceList.currentVersion === item.name
          }
        >
          {item.name}
        </option>
      );
    });

  const targetPriceListClickHandler = (event) => {
    var array = [...tempRevisions]; // make a separate copy of the array
    var id = event.currentTarget.id;
    if (event.currentTarget.checked === true) {
      array.push(Number(id));
    } else {
      var index = array.indexOf(Number(id));
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
    settempRevisions(array);
  };

  const targetPriceList = copyToTargets.map((item, i) => {
    return (
      <Form.Check
        key={i + item.name}
        label={item.name}
        id={item.id}
        name="group1"
        type="checkbox"
        onClick={targetPriceListClickHandler}
      />
    );
  });

  const fetchPriceRevisionList = () => {
    try {
      GET(
        `${API_URL}/api/v1/Prices/GetAllPriceListRevisions`,
        {},
        (response) => {
          setFullRevisionList(response.result);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const copyRevision = () => {
    if (
      (copyCost.current.checked === true ||
        copyWholeSalePrices.current.checked === true ||
        copyRetailPrices.current.checked === true) &&
      tempRevisions.length > 0
    ) {
      var obj = {
        sourceRevisionId: params.revisionId,
        targetRevisionIds: tempRevisions,
        copyCost: copyCost.current.checked,
        copyWholesale: copyWholeSalePrices.current.checked,
        copyRetail: copyRetailPrices.current.checked,
      };
      PUT(
        `${API_URL}/api/v1/Prices/CopyToPriceListRevision`,
        obj,
        (response) => {
          handleClose();
          DisplayResults(response, COPY_TO_SUCCESS);
        }
      );
    } else {
      ShowToast(TOAST_MODE.ERROR, "Please select valid values");
    }
  };

  const generateNewRevision = () => {
    setShowLoader(true);
    POST(
      `${API_URL}/api/v1/Prices/GeneratePriceListRevision?priceListId=` + priceListData.priceList.id,
      {},
      (response) => {
        setShowLoader(false);
        if (DisplayResults(response, "New Revision created")) {
          fetchPriceDetails(response.result.priceListId, response.result.id);
        }
      }
    );
  };

  const refreshProducts = () => {
    setShowLoader(true);
    PUT(
      `${API_URL}/api/v1/Prices/RefreshProducts?priceListRevisionId=` + priceListData.priceListRevisionId,
      { },
      (response) => {
        setShowLoader(false);
        if (DisplayResults(response, "Products updated")) {
          fetchPriceDetails(priceListData.priceList.id, priceListData.priceListRevisionId);
        }
      }
    );
  };

  const revisionChangeHandler = (event) => {
    fetchPriceDetails(params.pricelistId, priceRevision.current.value);
  };

  const removePriceListHandler = () => {
    DELETE(
      `${API_URL}/api/v1/Prices/RemovePriceList/?priceListId=` + params.pricelistId,
      {},
      (response) => {
        var message = response.result ?
            "The revision has been deleted." :
            "The revision was in use and has been inactivated.";
        if (DisplayResults(response, message)) {
          navigate("/pricelist");
        }
      }
    );
  };

  useEffect(() => {
    if (params.pricelistId) {
      fetchPriceDetails(params.pricelistId, params.revisionId);
      fetchPriceRevisionList();
    }
  }, []);

  function validateCells() {
    columnDefs.forEach(column => {
      if (column.field !== "Product") {
        gridRef.current.api.forEachNode((rowNode, index) => {
          const parsedNumber = Number.parseFloat(rowNode.data[column.field]);
          if (Number.isNaN(parsedNumber)) {
            rowNode.setDataValue(column.field,"0");
          } else if (parsedNumber != rowNode.data[column.field]) {
            rowNode.setDataValue(column.field,parsedNumber);
          }
        })
      }
    });
  };

  return (
    <div className="root-block">
      <h2>{PRICE_LIST_EDITOR}</h2>
      <Row>
        <Col xs={3}>
          <Row className="mg-bottom-10">
            <Col xs={4}>Name</Col>
            <Col xs={8}>
              <Form.Control
                type="text"
                placeholder="Enter name"
                maxLength={50}
                ref={name}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a name.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mg-bottom-10">
            <Col xs={4}>Terms</Col>
            <Col xs={4}>
              <Form.Control
                type="number"
                placeholder="Enter terms"
                ref={creditDays}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the terms.
              </Form.Control.Feedback>
            </Col>
            <Col xs={4}>(days)</Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row className="mg-bottom-10">
            <Col xs={4}>Revisions</Col>
            <Col xs={8}>
              <Form.Select
                aria-label="Revision"
                ref={priceRevision}
                onChange={revisionChangeHandler}
              >
                {revisionList}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mg-bottom-10">
            <Col xs={4}>Active Date</Col>
            <Col xs={8}>
              <DatePicker
                selected={activeDate}
                onChange={(date) => setActiveDate(date)}
                filterDate={FilterToSundays}
                placeholderText="Select a Date"
                dateFormat="MM/dd/yyyy"
                className="date-picker"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row className="mg-bottom-10">
            <Col xs={4}>Renewal Date</Col>
            <Col xs={8}>
              <DatePicker
                selected={renewalDate}
                onChange={(date) => setRenewalDate(date)}
                placeholderText="Select a Date"
                dateFormat="MM/dd/yyyy"
                className="date-picker"
              />
            </Col>
          </Row>
        </Col>
        {authCtx.writeRights.includes("PriceList") &&
          <Col xs={3}>
            <Row className="mg-bottom-10">
              <label
                onClick={showCopyDialog}
                style={{ textDecoration : "underline", color: "#0d6efd" }}
              >
                Copy To
              </label>
            </Row>
            <Row className="mg-bottom-10">
              <label
                onClick={generateNewRevision}
                style={{ textDecoration: "underline", color: "#0d6efd" }}
              >
                New Revision
              </label>
            </Row>
          </Col>
        }
      </Row>

      <div className="ag-theme-alpine" style={{ height: "70%" }}>
        {showLoader === true && (
          <Col>
            <div style={{ marginLeft: "50%", marginTop: "5%" }}>
              <Spinner animation="border" />
            </div>
            <div style={{ marginLeft: "48%", marginTop: "1%" }}>
              <h4 style={{ color: "#aaa" }}>Loading...</h4>
            </div>
          </Col>
        )}
        {showLoader === false && (
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            rowSelection="single"
            defaultColDef={defaultColDef}
            rowData={gridData}
            cacheQuickFilter={true}
            columnDefs={columnDefs}
            onCellEditingStopped={validateCells}
            onCellKeyDown={(e) => {
              NavigateKeyDown(e.event.key.toString(), gridRef)
            }}
            onCellClicked={(e) => { 
              EnterEditMode(e, gridRef) 
            }}
          ></AgGridReact>
        )}

        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <Col xs={1}>
            <Button 
              variant="outline-danger"
              className="button-standard"
              onClick={showRemoveDialog}
              disabled={!authCtx.writeRights.includes("PriceList")}>
              Delete
            </Button>
          </Col>

          <Col xs={3}></Col>
          <Col xs={4}>
            {needsProductRefresh && authCtx.writeRights.includes("PriceList") && (
              <label
                  onClick={refreshProducts}
                  style={{ "text-decoration": "underline", color: "red" }}
                >
                  New products found. Click to add them to this price list.
                </label>
            )}
          </Col>
          <Col xs={2}></Col>
          <Col xs={2}>
            <Row>
              <Col xs={6}>
                <Button 
                  className="button-standard" 
                  variant="outline-dark">
                  <Link to="/pricelist">Close</Link>
                </Button>
              </Col>
              <Col xs={6}>
                <Button 
                  className="button-standard"
                  variant="primary" 
                  type="button" 
                  onClick={saveHandler}
                  disabled={!authCtx.writeRights.includes("PriceList")}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal show={copyDialogVisibility} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Copy price list data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="copy-data-container">
            <Row>
              <Col xs={2}>Copy data</Col>
              <Col xs={3}>
                <Form.Check
                  key="cost"
                  label="Costs"
                  name="group2"
                  type="checkbox"
                  ref={copyCost}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  key="retailPrice"
                  label="Retail Prices"
                  name="group2"
                  type="checkbox"
                  ref={copyRetailPrices}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  key="wholeSalePrice"
                  label="Wholesale Prices"
                  name="group2"
                  type="checkbox"
                  ref={copyWholeSalePrices}
                />
              </Col>
            </Row>
          </div>
          <div className="target-container">{targetPriceList}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={copyRevision}>
            Copy
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={removeDialogVisibility} onHide={handleCloseRemoveDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the price list?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleCloseRemoveDialog}>
            NO
          </Button>
          <Button variant="danger" onClick={removePriceListHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PriceDetails;
