//Labels
export const ADD_USER = "Add User";
export const EDIT_USER = "Edit User";
export const ADD_LOCATION = "Add Location";
export const EDIT_LOCATION = "Edit Location";
export const ADD_ORDER = "Add Order";
export const EDIT_ORDER = "Edit Order";
export const ADD_PRODUCT = "Add Product";
export const EDIT_PRODUCT = "Edit Product";
export const ADD_KITCHEN = "Add Kitchen";
export const EDIT_KITCHEN = "Edit Kitchen";
export const PRICE_LIST_EDITOR = "Price List Editor";

//Messages
export const SOMETHING_WENT_WRONG = "Something went wrong. Please contact the admininstrator.";
export const DUPLICATE_USER = "Please enter a unique username and email.";
export const ISSUE_PASSWORD_RESET = "There was a problem resetting the password for this user.";
export const INCORRECT_USERNAME_PASSWORD = "It seems you have entered incorrect credentials.";
export const PASSWORD_CHANGE_SUCCESS = "The user password has been reset.";
export const PASSWORD_RESET = "The password has been reset succesfully. Please login with the new crendetials.";
export const FORGOT_PASSWORD_EMAIL = "The password reset link was sent to the registered email.";
export const USER_CREATED = "User created successfully";
export const USER_UPDATED = "User updated successfully";
export const USER_DELETION_FAILED = "There was an error while trying to delete the user.";
export const ORDER_DELETED = "Order deleted successfully";
export const ORDER_DELETION_FAILED = "There was an error while trying to delete the order.";
export const PRODUCT_CREATED = "Product created successfully";
export const PRODUCT_UPDATED = "Product updated successfully";
export const PRODUCT_DELETED = "Product deleted successfully";
export const PRICE_UPDATED = "Price updated successfully";
export const LOCATION_CREATED = "Location created successfully";
export const LOCATION_UPDATED = "Location updated successfully";
export const INVALID_KITCHEN_OR_LOCATION = "Please select a kitchen and a location.";
export const UNSUBSCRIBE_SUCCESS = "Settings updated successfully";
export const COPY_TO_SUCCESS = "Prices copied successfully";
export const INVOICE_FIELD_MISSING = "All invoice information must be filled to save an Invoiced order.";
export const INVOICE_CANCELLED = "Invoice information was removed because the order is not Invoiced.";
export const INVOICE_SUCCESS =  "The invoice was emailed successfully.";
export const CHANGES_SAVED = "Changes were saved successfully.";
export const IMAGES_DELETED = "The image was deleted successfully.";
export const DELIVERY_ITEM_DELETED_ADJUSTED = "The delivery item was deleted and the shrink quantity on the order was reduced.";
export const DELIVERY_ITEM_DELETED_NOT_ADJUSTED = "The delivery item was deleted successfully, but the shrink quantity on the order could not be reduced.";