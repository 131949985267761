export function ValidateEmails(value) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let allValid = true

    const emails = value.split(",");
    emails.forEach((email) => {
        if (regex.test(email) == false) {
        allValid = false;
        return;
        }
    });

    return allValid;
};