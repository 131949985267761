import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import API_URL from "../config";
import AuthContext from "../store/auth-context";
import { ORDERSTATUS } from "../constants/Common";
import { FilterToSaturdays } from "../services/DateHelper"
import { DisplayResults, GET, POST } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Users.css";
import "../components/Grid.css";

const OrderList = () => {
  const authCtx = useContext(AuthContext);
  const orderKitchen = useRef(1);
  const orderLocation = useRef();
  const [gridData, setGridData] = useState(null);
  const [kitchens, setKitchens] = useState({});
  const [locations, setLocations] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [orderDate, setOrderDate] = useState(null);
  const [showBulkCreate, setShowBulkCreate] = useState(false);
  const [bulkCreateDate, setBulkCreateDate] = useState(null);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [showGridSpinner, setShowGridSpinner] = useState(true);
  const [showBulkOrderSpinner, setShowBulkOrderSpinner] = useState(false);
  const statusFilter = useRef();
  const gridRef = useRef();
  const searchBox = useRef();

  const [lastServerStatus, setLastServerStatus] = useState();
  const [lastServerKitchenId, setLastServerKitchenId] = useState();
  const [lastServerLocationId, setLastServerLocationId] = useState();
  const [lastServerFilterDate, setLastServerFilterDate] = useState();

  const defaultColDef = {
    sortable: true,
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 200,
    },
  }

  const resetHandler = () => {
    setOrderDate(null);
    statusFilter.current.value = 0;
    orderKitchen.current.value = 0;
    orderLocation.current.value = 0;
    searchBox.current.value = "";

    refreshOrderList(false);
  }

  const userCellRenderer = (e) => {
    var userlink =
      "/orders/" +
      e.data.Order +
      "/" +
      e.data.LocationId +
      "/" +
      new Date(e.data.Date).toISOString().slice(0, 10);
    return (
      <Link className="user-link" to={userlink}>
        {e.data.Order}
      </Link>
    );
  };

  const [columnDefs] = useState([
    { field: "Order", flex: 1, cellRenderer: userCellRenderer, filter: true },
    {
      field: "Date",
      flex: 1,
      filter: false,
      cellRenderer: (data) => {
        return data.value ? new Date(data.value).toLocaleDateString() : "";
      },
    },
    {
      field: "Kitchen",
      flex: 2,
      filter: false,
    },
    { field: "Customer Location", flex: 2, filter: false },
    { field: "Ordered", flex: 1, filter: false },
    { field: "Shrink", flex: 1, filter: false },
    { field: "Status", flex: 1, filter: false },
  ]);

  const onFilterTextBoxChanged = () => {
    gridRef.current?.api?.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  };
  
  const fetchKitchens = useCallback(async () => {
    GET(
      `${API_URL}/api/v1/Kitchen/GetAllKitchens`,
      {},
      getKitchenDataCallback
    );
  }, []);

  const getKitchenDataCallback = (response) => {
    var temp = [
      {
        id: 0,
        name: "All",
      },
    ];

    if (DisplayResults(response, null)) {
      var data = response.result;
      // if (data.length == 1) {
      //   temp = [];
      // }
      
      data.forEach((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });

      setKitchens(temp);
      getLocationDataCallback(null);
    }
  }
  
  const fetchLocationHandler = (id) => {
    GET(
      `${API_URL}/api/v1/Location/GetAllLocations?kitchenId=` + id,
      {},
      getLocationDataCallback
    );
  }

  const getLocationDataCallback = (response) => {
    var temp = [
      {
        id: 0,
        name: "All",
      },
    ];

    if (response !== null && DisplayResults(response, null)) {
      var data = response.result;
      if (data != null) {
        // if (data.length == 1) {
        //   temp = [];
        // }

        data.forEach((d) => {
          var u = {
            id: d.id,
            name: d.name,
          };
          temp.push(u);
        });
      }
    }

    setLocations(temp);
    setSelectedLocationIds([]);
  };

  // Method to filter the data on the grid on the click of the RUN button
  const refreshOrderList = (forceRefresh) => {
    var statusId = Number(statusFilter.current.selectedOptions[0].attributes["data-keyid"].value);

    var selectedStatuses = statusId === 0 ? [1,2,3] : [statusId];
    var kitchenId = ["", "0"].includes(orderKitchen.current.value) ? null : Number(orderKitchen.current.value);
    var locationId = ["","0"].includes(orderLocation.current.value) ? null : Number(orderLocation.current.value);
    var filterDate = orderDate ? new Date(orderDate).toLocaleDateString() : null; //format yyyy-mm-dd

    const allStatusesExist = lastServerStatus != null && selectedStatuses.every((element) => lastServerStatus.includes(element));
    const kitchensExist = lastServerKitchenId === null || lastServerKitchenId === kitchenId;
    const locationsExist = lastServerLocationId === null || lastServerLocationId === locationId;
    const dateExists = lastServerFilterDate === null || lastServerFilterDate === filterDate;
    
    // If the search is narrowing, then filter on the client-side
    if (!forceRefresh && allStatusesExist && kitchensExist && locationsExist && dateExists) {
      var temp = [];

      originalData.forEach((d) => {
      if (selectedStatuses.includes(d.statusId) &&
        (kitchenId === null || kitchenId === d.kitchenId) &&
        (locationId === null || locationId === d.locationId) &&
        (filterDate === null || filterDate === new Date(d.endDate).toLocaleDateString())) {
          var includedRecord = {
            Order: d.orderNumber,
            Date: d.endDate,
            Kitchen: kitchens.find((o) => o.id === d.kitchenId).name,
            KitchenId: d.kitchenId,
            KitchenName: d.kitchenName,
            "Customer Location": d.customerLocation,
            LocationId: d.locationId,
            Ordered: d.totalDeliverQuantity,
            Shrink: d.totalShrinkQuantity,
            StatusId: d.statusId,
            Status: ORDERSTATUS.find((o) => o.id === d.statusId).name,
          };

          temp.push(includedRecord);
        }
      });

      setGridData(temp);
    } else {
      // If the search is broadening, then go back to the server and reset the local filters
      fetchOrderListHandler(selectedStatuses, kitchenId, locationId, filterDate);
    }
    
    searchBox.current.value = "";
    onFilterTextBoxChanged();
  }

  const fetchOrderListHandler = (selectedStatuses, kitchenId, locationId, filterDate) => {
    setShowGridSpinner(true);

    setLastServerStatus(selectedStatuses);
    var queryParameters = "?orderStatusIds=" + JSON.stringify(selectedStatuses);
    setLastServerKitchenId(kitchenId);
    if (kitchenId !== null) {
      queryParameters += "&kitchenId=" + kitchenId;
    }
    setLastServerLocationId(locationId);
    if (locationId !== null) {
      queryParameters += "&locationId=" + locationId;
    }
    setLastServerFilterDate(filterDate);
    if (filterDate !== null) {
      queryParameters += "&filterDate=" + filterDate;
    }

    GET(
      `${API_URL}/api/v1/Order/GetOrderList` + queryParameters,
      {},
      fetchOrderListCallback
    );
  };

  const fetchOrderListCallback = (response) => {
    var temp = [];

    if (DisplayResults(response, null)) {
      var data = response.result;
      data.forEach((d) => {
        var u = {
          Order: d.orderNumber,
          Date: d.endDate,
          Kitchen: d.kitchenName,
          "Customer Location": d.customerLocation,
          LocationId: d.locationId,
          Ordered: d.totalDeliverQuantity,
          Shrink: d.totalShrinkQuantity,
          Status: ORDERSTATUS.find((o) => o.id === d.statusId).name,
          StatusId: d.statusId,
        };
        temp.push(u);
      });
    }

    setGridData(temp);
    setOriginalData(data);

    setShowGridSpinner(false);
  };

  const kitchenChangeHandler = () => {
    fetchLocationHandler(orderKitchen.current.value);
  };

  useEffect(() => {
    fetchKitchens();
    refreshOrderList(false);

    function handleEnterKey(event) {
      if (event.key === "Enter") {
        refreshOrderList(false);
      }
    }

    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, []);

  const statusList = ORDERSTATUS.map((item, i) => {
    return (
      <option
        key={item + item.id}
        value={item.id}
        data-keyid={item.id}
        selected={item.id === 0}
      >
        {item.name}
      </option>
    );
  });

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option key={i + item.id} value={item.id} data-keyid={item.id}>
          {item.name}
        </option>
      );
    });

  const locationList =
    locations.length > 0 &&
    locations.map((item, i) => {
      return (
        <option key={i + item.id} value={item.id} data-keyid={item.id}>
          {item.name}
        </option>
      );
    });

  const handleBulkCreation = () => {
    try {
      setShowBulkOrderSpinner(true);

      var formattedDate = bulkCreateDate.getFullYear() + '-' + (bulkCreateDate.getMonth() + 1) + '-' + bulkCreateDate.getDate();
      var creationParameters = 
      {
        orderDate: formattedDate,
        customerLocationIds: selectedLocationIds
      };

      POST(
        `${API_URL}/api/v1/Order/BulkCreateWeeklyOrders`,
        creationParameters,
        (response) => {
          if (DisplayResults(response, response.result[0])) {
            toast.info(<div>
              {response.result[1]}<br/>
              {response.result[2]}<br/>
              {response.result[3]}<br/>
              {response.result[4]}<br/>
              {response.result[5]}<br/>
              </div>, { position: toast.POSITION.TOP_RIGHT });
              
            // Force a hard refresh from the server because we created more orders
            refreshOrderList(true);
          }
          
          setShowBulkCreate(false);
        }
      );
    } catch (err) {
      console.log(err);
    }

    setShowBulkOrderSpinner(false);
  }

  const targetLocationClickHandler = (event) => {
    var array = [...selectedLocationIds]; // make a separate copy of the array
    var id = event.currentTarget.id;
    if (event.currentTarget.checked) {
      array.push(Number(id));
    } else {
      var index = array.indexOf(Number(id));
      if (index !== -1) {
        array.splice(index, 1);
      }
    }

    setSelectedLocationIds(array);
  };

  const targetLocations = locations.map((item, i) => {
    return (
      <Form.Check
        key={i + item.name}
        label={item.name}
        id={item.id}
        type="checkbox"
        checked={selectedLocationIds.includes(item.id)}
        onChange={targetLocationClickHandler}
      />
    );
  });
  
  return (
    <div className="root-block">
      <div className="ag-theme-alpine" style={{ height: "76%" }}>
        <Row>
          <Col xs={1} style={{ paddingTop: "8px" }}>
            <Form.Label>Date</Form.Label>
          </Col>
          <Col xs={2}>
            <DatePicker
              selected={orderDate}
              onChange={(date) => setOrderDate(date)}
              filterDate={FilterToSaturdays}
              placeholderText="Select a Saturday"
              dateFormat="MM/dd/yyyy"
              className="date-picker"
            />
          </Col>
          <Col xs={1} style={{ paddingTop: "8px" }}>
            <Form.Label>Status</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default Role example" ref={statusFilter}>
              {statusList}
            </Form.Select>
          </Col>
          <Col xs={1} className="label" style={{ paddingTop: "0px" }}>
            <Form.Label>Production Kitchen</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Select
              aria-label="Default label"
              ref={orderKitchen}
              className="label"
              onChange={kitchenChangeHandler}
            >
              {kitchenList}
            </Form.Select>
          </Col>
          <Col xs={1} className="label" style={{ paddingTop: "0px" }}>
            <Form.Label>Customer Location</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Select
              aria-label="Default label"
              ref={orderLocation}
            >
              {locationList}
            </Form.Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Col xs={2}>
            <input
              type="text"
              className="search-box"
              id="filter-text-box"
              placeholder="Filter..."
              ref={searchBox}
              onInput={onFilterTextBoxChanged}
            />
          </Col>

          <Col xs={1}>
            <Button 
              className="button-standard"
              onClick={() => refreshOrderList(false)}>
                Run
            </Button>
          </Col>
          <Col xs={6}>
            <Button 
              className="button-standard"
              variant="outline-primary"
              onClick={resetHandler}>
              Reset
            </Button>
          </Col>
          {authCtx.writeRights.includes("OrderList") && 
            <Col xs={2} style={{ paddingTop: "9px" }}>
              {locations.length > 1 && 
              <Link className="user-link" onClick={() => {setShowBulkCreate(true);}}>
                + Bulk Order Creation
              </Link>}
            </Col>
          }
          {authCtx.writeRights.includes("OrderList") && 
            <Col xs={1} style={{ paddingTop: "9px" }}>
              <Link className="user-link" to="/orders/add">
                + New Order
              </Link>
            </Col>
          }
        </Row>
      <Modal
        show={showBulkCreate}
        onHide={() => {setShowBulkCreate(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Create Weekly Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Form.Label>Order Week</Form.Label>
          <DatePicker
            selected={bulkCreateDate}
            onChange={(date) => { setBulkCreateDate(date) }}
            filterDate={FilterToSaturdays}
            dateFormat="MM/dd/yyyy"
            className="date-picker"
          />
          <Row style={{paddingTop: "15px"}}>
            <Col><Form.Label>Orders to Generate</Form.Label></Col>
            <Col xs={3}>
              {selectedLocationIds.length < locations.length && 
              <Link className="user-link" onClick={() => {setSelectedLocationIds(locations.forEach(location => (location.id)))}}>
                Select All
              </Link>}
            </Col>
            <Col xs={3}>
              {selectedLocationIds.length > 0 && 
              <Link className={"user-link"} onClick={() => {setSelectedLocationIds([])}}>
                Unselect All
              </Link>}
            </Col>
          </Row>
          <div className="target-container" style={{height: "calc(100vh - 400px)"}}>{targetLocations}</div>
        </Modal.Body>
        <Modal.Footer>
          {showBulkOrderSpinner && (
            <Col>
              <Spinner animation="border" />
            </Col>
          )}
          <Button 
            className="button-standard"
            variant="secondary"
            onClick={() => {setShowBulkCreate(false)}}>
            Close
          </Button>
          <Button 
            className="button-standard"
            variant="primary"
            disabled={bulkCreateDate === null || selectedLocationIds.length === 0}
            onClick={handleBulkCreation}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

        {(gridData == null || showGridSpinner) && (
          <Col>
            <div style={{ marginLeft: "50%", marginTop: "5%" }}>
              <Spinner animation="border" />
            </div>
            <div style={{ marginLeft: "48%", marginTop: "1%" }}>
              <h4 style={{ color: "#aaa" }}>Loading...</h4>
            </div>
          </Col>
        )}
        {(gridData != null && !showGridSpinner) && (
          <AgGridReact
            ref={gridRef}
            rowSelection="single"
            defaultColDef={defaultColDef}
            rowData={gridData}
            columnDefs={columnDefs}
          ></AgGridReact>
        )}
      </div>
    </div>
  );
};

export default OrderList;
