import jsPDF from "jspdf";
import { format } from "date-fns";
import API_URL from "../config";
import logo from "../images/anu_logo.png";
import { DisplayResults, GET, POST } from "../services/httpClient";
import { INVOICE_SUCCESS, SOMETHING_WENT_WRONG } from "../constants/Messages";
import "jspdf-autotable";

var updateInvoiceCallback;
var updatePDFCallback;

export function BuildInvoice(orderId, invoiceReferenceSetter, pdfReferenceSetter) {
    updateInvoiceCallback = invoiceReferenceSetter;
    updatePDFCallback = pdfReferenceSetter;

    try {
        GET(
            `${API_URL}` + "/api/v1/Order/GetInvoice?orderId=" + orderId,
            {},
            getInvoiceCallback
        );
    } catch (err) {
    console.log(err);
    }
};

const getInvoiceCallback = (response) => {
    if (!DisplayResults(response, null)) {
        return;
    }
    
    var invoice = response.result;

    const dateFormat = "M/d/yyyy";
    const lineSpacing = 7;
    const topMargin = 15;
    const lineMargin = topMargin - 3;
    const col1Margin = 15;
    const col2Margin = 100;
    const col3Margin = 175;
    const col4Margin = 250;
    const showVendorNumber = invoice.vendorNumber && invoice.vendorNumber.length > 0;
    const showReferenceNumber = invoice.referenceNumber && invoice.referenceNumber.length > 0;    

    var doc = new jsPDF("landscape");    
    // doc.getFont(); //defaults: "helvetica", "normal"

    // *** Title ***
    doc.setFontSize(24);
    doc.text("Invoice", col1Margin, topMargin); // text, margin-top, margin-left
    doc.setDrawColor(201, 76, 77); // draw red lines
    doc.setLineWidth(1.5);
    doc.line(col1Margin + 32, lineMargin, 240, lineMargin);

    // *** Header ***
    doc.setFontSize(12);
    // Column 1
    // Show a maximum of two blocks of customer name
    var nextRowStart = 1;
    const splitCustomerName = doc.splitTextToSize(invoice.customer, 85);
    doc.text(splitCustomerName.slice(0, 2), col1Margin, topMargin + lineSpacing * nextRowStart); // Takes 1-2 lines
    nextRowStart += splitCustomerName.length === 1 ? 1 : 1.5;

    const splitAddress = doc.splitTextToSize(invoice.address, 85);
    doc.text(splitAddress.slice(0, 3), col1Margin, topMargin + lineSpacing * nextRowStart); // Takes 2-3 lines
    nextRowStart += splitAddress.length <= 2 ? 2 : 2.5;

    doc.text("Attention:" + invoice.contact, col1Margin, topMargin + lineSpacing * nextRowStart);
    nextRowStart += 1;

    if (showVendorNumber) {
        doc.text("Vendor#: " + invoice.vendorNumber, col1Margin, topMargin + lineSpacing * nextRowStart);
        nextRowStart += 1;
    }

    // Column 2
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Number: " + invoice.invoiceNumber, col2Margin, topMargin + lineSpacing * 1);
    doc.text("Due Date: " + format(new Date(invoice.dueDate), dateFormat), col2Margin, topMargin + lineSpacing * 2);
    doc.setFont("helvetica", "normal");
    doc.text("Week Ending: " + format(new Date(invoice.orderDate), dateFormat), col2Margin, topMargin + lineSpacing * 3);
    doc.text("Invoice Date: " + format(new Date(invoice.invoiceDate), dateFormat), col2Margin, topMargin + lineSpacing * 4);
    doc.text("Terms: " + invoice.displayTerms, col2Margin, topMargin + lineSpacing * 5);
    if (showReferenceNumber) {
        doc.text("Reference#: " + invoice.referenceNumber, col2Margin, topMargin + lineSpacing * 6);
    }

    // Column 3
    doc.text("Remit To: " + invoice.remitTo, col3Margin, topMargin + lineSpacing * 1); // Takes 3 lines
    doc.text("Prepared At: " + invoice.preparedAt, col3Margin, topMargin + lineSpacing * 4);

    // Column 4
    doc.addImage(logo, 'PNG', col4Margin + 5, topMargin - 5, 20, 33); // Original size: 80x131
    doc.setFont("helvetica", "bold");
    doc.text("Total: " + "$" + invoice.invoiceTotal.toFixed(2), col4Margin, topMargin + lineSpacing * 5);

    // *** Grid ***
    // Grid header
    const tableColumns = GenerateColumns(invoice);
    const tableRows = GenerateRows(invoice);

    // Grid options
    const startLine = showVendorNumber || showReferenceNumber ? 7 : 6;
    doc.autoTable(tableColumns, tableRows, {
            //theme: "grid", // 'striped' is the default
            styles: { fontSize: 7, cellPadding: 1, halign: "center" }, // cellPadding = 1 shrinks the row height
            headStyles: { halign: "center", valign: "middle", fillColor: [201, 76, 77] }, // Cells in first column centered and colored
            columnStyles: { 0: { halign: "left" } },
            startY: topMargin + lineSpacing * startLine, // startY is basically margin-top
        });

    updateInvoiceCallback(invoice);
    updatePDFCallback(doc);
}

export function ExportPDF(invoice, doc) {
    doc.save(`Invoice_${invoice.invoiceNumber}.pdf`);
}

export function EmailPDF(invoice, doc, customizedSubject, customizedBody) {
    const binary = doc.output();
    POST(
        `${API_URL}` + 
        "/api/v1/Order/EmailInvoice",
        {
            OrderId: invoice.orderId,
            EmailSubject: customizedSubject,
            EmailBody: customizedBody,
            BinaryData: window.btoa(binary)
        },
        (response) => {
            DisplayResults(response, INVOICE_SUCCESS);
        }
    );
}

function GenerateColumns(invoice) {
    let tableColumns = ["Product", "Retail", "Wholesale"]; // Standard columns
    if (invoice.hasSunData) { // Only Sun if data exists
        tableColumns = [...tableColumns, "Sun\r\nOrder", "Sun\r\nUnsold"];
    }
    tableColumns = [...tableColumns, "Mon\r\nOrder", "Mon\r\nUnsold", "Tue\r\nOrder", "Tue\r\nUnsold", // Standard columns
        "Wed\r\nOrder", "Wed\r\nUnsold", "Thu\r\nOrder", "Thu\r\nUnsold", "Fri\r\nOrder", "Fri\r\nUnsold"];
    if (invoice.hasSatData) { // Only Sat if data exists
        tableColumns = [...tableColumns, "Sat\r\nOrder", "Sat\r\nUnsold"]
    }
    tableColumns = [...tableColumns, "Total", "Invoice"]; //Standard columns

    return tableColumns
}

function GenerateRows(invoice) {
    const blankRowNames = ["Total", "Processing Fee"];    
    const tableRows = [];
    invoice.lineItems.forEach(orderItem => {
        let itemData = [
            orderItem.productName,
            blankRowNames.includes(orderItem.productType) ? null : "$" + orderItem.retailPrice.toFixed(2),
            blankRowNames.includes(orderItem.productType) ? null : "$" + orderItem.wholesalePrice.toFixed(2)];
        if (invoice.hasSunData) {
            itemData = [...itemData, orderItem.sunOrder, orderItem.sunShrink];
        }
        itemData = [...itemData,                
            orderItem.monOrder,
            orderItem.monShrink,
            orderItem.tueOrder,
            orderItem.tueShrink,
            orderItem.wedOrder,
            orderItem.wedShrink,
            orderItem.thuOrder,
            orderItem.thuShrink,
            orderItem.friOrder,
            orderItem.friShrink];
        if (invoice.hasSatData) {
            itemData = [...itemData, orderItem.satOrder, orderItem.satShrink];
        }
        itemData = [...itemData,   
            orderItem.weekTotal,
            "$" + orderItem.weekInvoice.toFixed(2)];

        tableRows.push(itemData);                
    });

    return tableRows;
};