export function FindNextSaturday() {
    var today = new Date();
    var daysUntilSaturday = 6 - today.getDay();
    if (daysUntilSaturday <= 0) {
      daysUntilSaturday += 7;
    }

    var nextSaturday = new Date(
      today.getTime() + daysUntilSaturday * 24 * 60 * 60 * 1000
    );

    return nextSaturday;
};


export function FilterToSaturdays(date) {
  // Disable all dates except Saturdays
  return date.getDay() === 6;
}

export function FilterToSundays(date) {
  // Disable all dates except Sundays
  return date.getDay() === 0;
}