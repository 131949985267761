import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import UserList from "./pages/UserList";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import OrderList from "./pages/OrderList";
import PriceList from "./pages/PriceList";
import UserDetails from "./pages/UserDetails";
import SalesReport from "./pages/SalesReport";
import ProductList from "./pages/ProductList";
import Unsubscribe from "./pages/Unsubscribe";
import AuthContext from "./store/auth-context";
import PriceDetails from "./pages/PriceDetails";
import OrderDetails from "./pages/OrderDetails";
import ProductReport from "./pages/ProductReport";
import ProductDetails from "./pages/ProductDetails";
import LocationDetails from "./pages/LocationDetails";
import DeliveryDetails from "./pages/DeliveryDetails";
import ForecastingReport from "./pages/ForecastingReport";
import CustomerLocationList from "./pages/CustomerLocationList";
import ProductionKitchenList from "./pages/ProductionKitchenList";
import ProductionKitchenDetails from "./pages/ProductionKitchenDetails";
import ProductionKitchenImageList from "./pages/ProductionKitchenImageList";
import ProductionKitchenImageDetails from "./pages/ProductionKitchenImageDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        {isLoggedIn && <Route path="/dashboard" element={<Dashboard />} />}

        {isLoggedIn && <Route path="/users" element={<UserList />} />}
        {isLoggedIn && <Route path="/users/add" element={<UserDetails />} />}
        {isLoggedIn && (
          <Route path="/users/:userId" element={<UserDetails />} />
        )}

        {isLoggedIn && <Route path="/salesreport" element={<SalesReport />} />}

        {isLoggedIn && (
          <Route path="/productreport" element={<ProductReport />} />
        )}

        {isLoggedIn && (
          <Route path="/forecastingreport" element={<ForecastingReport />} />
        )}

        {isLoggedIn && (
          <Route
            path="/productionkitchen"
            element={<ProductionKitchenList />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/productionkitchen/:kitchenId"
            element={<ProductionKitchenDetails />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/productionkitchen/add"
            element={<ProductionKitchenDetails />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/productionkitchen/:kitchenId/kitchenimages"
            element={<ProductionKitchenImageList />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/productionkitchen/:kitchenId/kitchenimages/:kitchenImageId"
            element={<ProductionKitchenImageDetails />}
          />
        )}

        {isLoggedIn && <Route path="/products" element={<ProductList />} />}
        {isLoggedIn && (
          <Route path="/products/add" element={<ProductDetails />} />
        )}
        {isLoggedIn && (
          <Route path="/products/:productId" element={<ProductDetails />} />
        )}
        {isLoggedIn && <Route path="/orders" element={<OrderList />} />}
        {isLoggedIn && (
          <Route
            path="/orders/:orderId/:locationId/:weekEndingDate"
            element={<OrderDetails />}
          />
        )}
        {isLoggedIn && <Route path="/orders/add" element={<OrderDetails />} />}
        {isLoggedIn && (
          <Route
            path="/orders/deliverydetails/:orderId/:deliveryId"
            element={<DeliveryDetails />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/customerlocationlist"
            element={<CustomerLocationList />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/customerlocationlist/add"
            element={<LocationDetails />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/customerlocationlist/:locationId"
            element={<LocationDetails />}
          />
        )}
        {isLoggedIn && <Route path="/pricelist" element={<PriceList />} />}
        {isLoggedIn && (
          <Route path="/pricedetails/:pricelistId/:revisionId" element={<PriceDetails />} />
        )}
        {isLoggedIn && (
          <Route path="/pricedetails/add" element={<PriceDetails />} />
        )}

        {/* {!isLoggedIn && (
          <Route path="*" element={<Navigate replace to="/login" />} />
        )} */}
      </Routes>
    </Layout>
  );
}

export default App;
