import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  refreshtoken: "",
  username: "",
  userId: 0,
  roleId: 0,
  readRights: [],
  writeRights: [],
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const intialToken = localStorage.getItem("token");
  const [token, setToken] = useState(intialToken);
  const [refreshToken, setRefreshToken] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [roleId, setRoleId] = useState(localStorage.getItem("roleId"));
  const [readPermissionsText, setReadPermissionsText] = useState(localStorage.getItem("readPermissions") ?? "");
  const [writePermissionsText, setWritePermissionsText] = useState(localStorage.getItem("writePermissions") ?? "");

  const userIsLoggedIn = !!token;

  const loginHandler = (user) => {
    localStorage.setItem("token", user.token);
    setToken(user.token);
    
    localStorage.setItem("refreshToken", user.refreshToken);
    setRefreshToken(user.refreshToken);

    localStorage.setItem("username", user.name);
    setUsername(user.name);

    localStorage.setItem("userId", user.id);
    setUserId(user.id);

    localStorage.setItem("roleId", user.roleId);
    setRoleId(user.roleId);

    // Permissions come back from the server as an array, but we write it to local storage as a string
    localStorage.setItem("readPermissions", user.readPermissions.toString());
    setReadPermissionsText(user.readPermissions.toString());
    localStorage.setItem("writePermissions", user.writePermissions.toString());
    setWritePermissionsText(user.writePermissions.toString());
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("roleId");
    localStorage.removeItem("readPermissions");
    localStorage.removeItem("writePermissions");
  };

  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    username: username,
    userId: userId,
    roleId: roleId,
    // The permissions are saved/loaded from local storage as a string, but used in the app as an array
    readRights: readPermissionsText.split(","),
    writeRights: writePermissionsText.split(","),
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
