import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const toastConfig = {
  position: toast.POSITION.TOP_RIGHT,
};

export function ShowToast(type, message) {
  switch (type) {
    case 1: {
      toast.success(message, {
        position: toastConfig.position,
      });
      break;
    }
    case 2: {
      toast.info(message, {
        position: toastConfig.position,
      });

      break;
    }
    case 3: {
      toast.warn(message, {
        position: toastConfig.position,
      });
      break;
    }
    case 4: {
      toast.error(message, {
        position: toastConfig.position,
      });
      break;
    }
    default: {
      break;
    }
  }
}
