// import Button from "react-bootstrap/Button";
import { useControls } from "react-zoom-pan-pinch";

const ZoomControls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    const zoomButtonStyle = { width: "30px", height: "30px", margin: "10px" }

    return (
        <div className="tools" style={{display: "flex", justifyContent: "center"}}>
            {/* <Button className="zoomButtonStyle" onClick={() => zoomIn()}>+</Button>
            <Button className={zoomButtonStyle} onClick={() => zoomOut()}>-</Button>
            <Button className={zoomButtonStyle} onClick={() => resetTransform()}>x</Button> */}
        <button style={zoomButtonStyle} onClick={() => resetTransform()}>↔️</button>
        <button style={zoomButtonStyle} onClick={() => zoomIn()}>+</button>
        <button style={zoomButtonStyle} onClick={() => zoomOut()}>-</button>
        </div>
    );
};

export default ZoomControls;