import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import API_URL from "../config";
import Grid from "../components/Grid";
import AuthContext from "../store/auth-context";
import { DisplayResults, GET } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Users.css";

const ProductionKitchenList = () => {
  const authCtx = useContext(AuthContext);
  const [gridData, setGridData] = useState(null);
  const getDataCallback = (response) => {
    var temp = [];

    if (DisplayResults(response) && response.statusCode != 204) {
      var data = response.result;
      data.map((d) => {
        var u = {
          Id: d.id,
          "Production Kitchen": d.kitchenName,
          Address: d.address,
          City: d.city,
          State: d.state,
          Zip: d.zip,
          Users: d.userCount
        };
        temp.push(u);
      });
    }

    setGridData(temp);
  };

  const userCellRenderer = (e) => {
    var userlink = "/productionkitchen/" + e.data.Id;
    return (
      <Link className="user-link" to={userlink}>
        {e.data["Production Kitchen"]}
      </Link>
    );
  };

  const [columnDefs] = useState([
    { field: "Kitchen", flex: 2, cellRenderer: userCellRenderer },
    { field: "Address", flex: 2 },
    { field: "City", flex: 1 },
    { field: "State", flex: 1 },
    { field: "Zip", flex: 1 },
    { field: "Users", flex: 1 },
  ]);

  const fetchKitchenHandler = useCallback(async () => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Kitchen/GetProductionKitchens",
        {},
        getDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchKitchenHandler();
  }, []);

  return (
    <div className="root-block">
      <Row style={{ float: "right" }}>
        {authCtx.writeRights.includes("KitchenList") &&
        <Link className="user-link" to="/productionkitchen/add">
          + Add Production Kitchen
        </Link>}
      </Row>
      <Grid
        sortable={true}
        filter={true}
        data={gridData}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default ProductionKitchenList;
