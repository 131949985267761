import { Link } from "react-router-dom";
import { FormLabel } from "react-bootstrap";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const PageCard = (props) => {

  return (
    <Col>
      <Card border="primary" style={{ width: "18rem", margin: "auto" }} onClick={props.onClick}>
        <Card.Header>
          {!props.enabled && <FormLabel>{props.header}</FormLabel>}
          {props.enabled &&<Link to={props.link}>{props.header}</Link>}
        </Card.Header>
        <Card.Body>
          <Card.Text>{props.desc}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default PageCard;
