export const TOAST_MODE = {
  SUCCESS: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
};

export const ROLES = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Driver" },
  { id: 3, name: "Offsite Recorder" },
  { id: 4, name: "Production" }
];

export const REVISIONS = [
  { id: 1, name: "v01" },
  { id: 2, name: "v02" },
  { id: 3, name: "v03" },
  { id: 4, name: "v04" },
  { id: 5, name: "v05" },
  { id: 6, name: "v06" },
  { id: 7, name: "v07" },
  { id: 8, name: "v08" },
  { id: 9, name: "v09" },
  { id: 10, name: "v10" },
  { id: 11, name: "v11" },
  { id: 12, name: "v12" },
  { id: 13, name: "v13" },
  { id: 14, name: "v14" },
  { id: 15, name: "v15" },
  { id: 16, name: "v16" },
  { id: 17, name: "v17" },
  { id: 18, name: "v18" },
  { id: 19, name: "v19" },
  { id: 20, name: "v20" },
  { id: 21, name: "v21" },
  { id: 22, name: "v22" },
  { id: 23, name: "v23" },
  { id: 24, name: "v24" },
  { id: 25, name: "v25" },
  { id: 26, name: "v26" },
  { id: 27, name: "v27" },
  { id: 28, name: "v28" },
  { id: 29, name: "v29" },
  { id: 30, name: "v30" },
  { id: 31, name: "v31" },
  { id: 32, name: "v32" },
  { id: 33, name: "v33" },
  { id: 34, name: "v34" },
  { id: 35, name: "v35" },
  { id: 36, name: "v36" },
  { id: 37, name: "v37" },
  { id: 38, name: "v38" },
  { id: 39, name: "v39" },
  { id: 40, name: "v40" },
  { id: 41, name: "v41" },
  { id: 42, name: "v42" },
  { id: 43, name: "v43" },
  { id: 44, name: "v44" },
  { id: 45, name: "v45" },
  { id: 46, name: "v46" },
  { id: 47, name: "v47" },
  { id: 48, name: "v48" },
  { id: 49, name: "v49" },
  { id: 50, name: "v50" },
];


export const PRODUCTTYPES = [
  { id: 1, name: "Sushi" },
  { id: 2, name: "Sides" },
];

export const STATES = [
  {
    id: 1,
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    id: 2,
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    id: 3,
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    id: 4,
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    id: 5,
    name: "California",
    abbreviation: "CA",
  },
  {
    id: 6,
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    id: 7,
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    id: 8,
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    id: 9,
    name: "Florida",
    abbreviation: "FL",
  },
  {
    id: 10,
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    id: 11,
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    id: 12,
    name: "Idaho",
    abbreviation: "id",
  },
  {
    id: 13,
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    id: 14,
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    id: 15,
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    id: 16,
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    id: 17,
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    id: 18,
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    id: 19,
    name: "Maine",
    abbreviation: "ME",
  },
  {
    id: 20,
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    id: 21,
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    id: 22,
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    id: 23,
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    id: 24,
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    id: 25,
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    id: 26,
    name: "Montana",
    abbreviation: "MT",
  },
  {
    id: 27,
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    id: 28,
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    id: 29,
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    id: 30,
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    id: 31,
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    id: 32,
    name: "New York",
    abbreviation: "NY",
  },
  {
    id: 33,
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    id: 34,
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    id: 35,
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    id: 36,
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    id: 37,
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    id: 38,
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    id: 39,
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    id: 40,
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    id: 41,
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    id: 42,
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    id: 43,
    name: "Texas",
    abbreviation: "TX",
  },
  {
    id: 44,
    name: "Utah",
    abbreviation: "UT",
  },
  {
    id: 45,
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    id: 46,
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    id: 47,
    name: "Washington",
    abbreviation: "WA",
  },
  {
    id: 48,
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    id: 49,
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    id: 50,
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const STATUS = [
  { id: 1, name: "Draft" },
  { id: 2, name: "Open" },
  { id: 3, name: "Counted" },
  { id: 4, name: "Invoiced" },
  { id: 5, name: "Cancelled" },
];

export const ORDERSTATUS = [
  { id: 0, name: "All current orders" },
  { id: 1, name: "Draft" },
  { id: 2, name: "Open" },
  { id: 3, name: "Counted" },
  { id: 4, name: "Invoiced" },
  { id: 5, name: "Cancelled" },
];
