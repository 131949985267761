import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import API_URL from "../config";
import Grid from "../components/Grid";
import { ShowToast } from "../services/Toast";
import AuthContext from "../store/auth-context";
import { TOAST_MODE } from "../constants/Common";
import { DisplayResults, GET, POST } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Users.css";

const PriceList = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const [validated, setValidated] = useState(false);
  const priceListname = useRef("");
  const [addPriceListVisibility, setAddPriceListVisibility] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const priceCellRenderer = (e) => {
    var link = "/pricedetails/" + e.data.Id + "/" + e.data.currentVersionId;
    return (
      <Link className="user-link" to={link}>
        {e.data["Price List"]}
      </Link>
    );
  };

  const showAddPriceDialog = () => {
    setAddPriceListVisibility(true);
    setValidated(false);
  };
  const hideAddPriceDialog = () => {
    setAddPriceListVisibility(false);
  };

  const [columnDefs] = useState([
    { field: "Price List", flex: 1, cellRenderer: priceCellRenderer },
    { field: "Terms", flex: 1 },
    { field: "Renewal Date", flex: 1 },
    { field: "Current Version", flex: 1 },
    { field: "Next Version", flex: 1 },
  ]);

  const getPriceDetailsCallback = (response) => {
    var temp = [];
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          Id: d.id,
          "Price List": d.name,
          Terms: d.displayTerms,
          "Renewal Date":
            d.renewalDate != null
              ? new Date(d.renewalDate).toISOString().slice(0, 10)
              : null,
          "Current Version": d.currentVersion,
          "Next Version": d.nextVersion,
          currentVersionId: d.currentVersionId,
        };
        temp.push(u);
      });
    }
    
    setShowLoader(false);
    setGridData(temp);
  };

  const fetchPriceList = useCallback(async () => {
    try {
      GET(
        `${API_URL}/api/v1/Prices/GetAllPriceLists`,
        {},
        getPriceDetailsCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const createVersion = (id) => {
    POST(
      `${API_URL}` +
        "/api/v1/Prices/GeneratePriceListRevision?priceListId=" +
        id,
      {},
      (response) => {
        setShowLoader(false);
        if (response.isSuccess == true) {
          navigate(
            "/pricedetails/" +
              response.result.priceListId +
              "/" +
              response.result.id
          );
        } else {
          ShowToast(TOAST_MODE.ERROR, response.errorMessages[0]);
        }
      }
    );
  };

  const createPriceList = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setShowLoader(true);
      POST(
        `${API_URL}` + "/api/v1/Prices/AddPriceList",
        {
          id: 0,
          name: priceListname.current.value,
        },
        (response) => {
          if (response.isSuccess == true) {
            createVersion(response.result.id);
          } else {
            ShowToast(TOAST_MODE.ERROR, response.errorMessages[0]);
          }
        }
      );
    }
  };
  useEffect(() => {
    setShowLoader(true);
    fetchPriceList();
  }, []);

  return (
    <div className="root-block">
      {authCtx.writeRights.includes("ProductList") &&
        <Row style={{ float: "right" }}>
          <label
            className="user-link"
            onClick={showAddPriceDialog}
            style={{ textDecoration : "underline", color: "#0d6efd" }}
          >
            + Add New Price List
          </label>
        </Row>
      }
      {showLoader == true && (
        <Col>
          <div style={{ marginLeft: "50%", marginTop: "5%" }}>
            <Spinner animation="border" />
          </div>
          <div style={{ marginLeft: "48%", marginTop: "1%" }}>
            <h4 style={{ color: "#aaa" }}>Loading...</h4>
          </div>
        </Col>
      )}
      {showLoader == false && (
        <Grid
          sortable={true}
          filter={true}
          data={gridData}
          columnDefs={columnDefs}
        />
      )}
      <Modal show={addPriceListVisibility} onHide={hideAddPriceDialog}>
        <Form noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>Add Price List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Name</Form.Label>
              </Col>
              <Col xs={9}>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  ref={priceListname}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a name.
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={hideAddPriceDialog}>
              No
            </Button>
            <Button variant="primary" type="button" onClick={createPriceList}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default PriceList;
