import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import API_URL from "../config";
import AuthContext from "../store/auth-context";
import { DELETE, DisplayResults, GET } from "../services/httpClient";
import { DELIVERY_ITEM_DELETED_ADJUSTED, DELIVERY_ITEM_DELETED_NOT_ADJUSTED } from "../constants/Messages";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./OrderDetails.css";

const DeliveryDetails = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const [showGallery, setShowGallery] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedDeliveryItem, setSelectedDeliveryItem] = useState();
  const [signatureImage, setSignatureImage] = useState([]);
  const [viewerImages, setViewerImages] = useState([]);
  const gridRef = useRef();
  const kitchen = useRef();
  const location = useRef();
  const dropOffTimestamp = useRef();
  const driverNotes = useRef();
  const [productGridData, setProductGridData] = useState([]);

  const handleClose = () => {
    setShowGallery(false);
    setShowDeleteConfirmation(false);
  };

  const handleShowSignature = () => {
    setViewerImages(signatureImage);
    setShowGallery(true);
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 200,
    },
  };

  const userCellRenderer = (e) => {
    var userlink =
      "/orders/" +
      e.data.OrderNumber +
      "/" +
      e.data.LocationId +
      "/" +
      new Date(e.data.OrderDate).toISOString().slice(0, 10);
    return (
      <Link className="user-link" to={userlink}>
        {e.data.OrderNumber}
      </Link>
    );
  };

  const deleteCellRenderer = (e) => {
    if (!authCtx.writeRights.includes("RecordShrink") || !authCtx.writeRights.includes("OrderList")) {
      return <div></div>;
    }

    return <Button style={{marginBottom: "6px"}} onClick={() => {
      setSelectedDeliveryItem(e.data.DeliveryItemId)
      setShowDeleteConfirmation(true);
      }}>
      X
    </Button>
  };

  const [columnDefs] = useState([
    { field: "DeliveryItemId", hide: true },
    { field: "Product", flex: 2 },
    { field: "ProductType", flex: 1 },
    { field: "Quantity", flex: 1 },
    { field: "AppliedOrder", flex: 1, cellRenderer: userCellRenderer },
    {
      field: "AppliedDate",
      flex: 2,
      valueFormatter: (params) => {
        return format(new Date(params.value), "LL/dd/yyyy (EE)");
      },
    },
    {
      field: "Delete",
      flex: 1,
      cellRenderer: deleteCellRenderer
    }
  ]);

  const deliveryDetailsCallback = (response) => {
    var temp = [];
    var tempSigImages = [];
    if (DisplayResults(response)) {
      var data = response.result;
      kitchen.current.value = data.kitchen;
      location.current.value = data.location;
      dropOffTimestamp.current.value = new Date(data.deliveryTime
        ).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      driverNotes.current.value = data.deliveryNotes;

      if (data.signatureUrl != null) {
        tempSigImages.push({
          original: data.signatureUrl,
          thumbnail: data.signatureUrl,
        });
        setSignatureImage(tempSigImages);
      }

      data.deliveryItems.map((d) => {
        var u = {
          DeliveryItemId: d.id,
          Product: d.product.name,
          ProductType: d.product.productType == 1 ? "Sushi" : "Side",
          Quantity: d.quantity,
          OrderId: d.appliedOrder.id,
          OrderNumber: d.appliedOrder.orderNumber,
          LocationId: d.appliedOrder.locationId,
          OrderDate: d.appliedOrder.endDate,
          AppliedDate: d.appliedDate,
        };
        temp.push(u);
      });     
      setProductGridData(temp);
    }
  };

  useEffect(() => {
    fetchDeliveryDetails(params.orderId, params.deliveryId);
  }, []);

  const fetchDeliveryDetails = useCallback(async (orderId, deliveryId) => {
    try {
      GET(
        `${API_URL}` +
          "/api/v1/Delivery/GetDeliveryDetails?orderId=" +
          orderId +
          "&deliveryId=" +
          deliveryId,
        {},
        deliveryDetailsCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const deleteDeliveryItem = (deliveryItemId) => {
    DELETE(
      `${API_URL}/api/v1/Delivery/RemoveDeliveryItem?deliveryId=` +
      params.deliveryId +
      "&deliveryItemId=" +
      deliveryItemId,
      {},
      (response) => {
        let successMessage = response.result === 1 ? DELIVERY_ITEM_DELETED_ADJUSTED : DELIVERY_ITEM_DELETED_NOT_ADJUSTED;
        if (DisplayResults(response, successMessage)) {
          fetchDeliveryDetails(params.orderId, params.deliveryId);
        }
      }
    )

    setSelectedDeliveryItem(null);
  }

  return (
    <div className="root-block">
      <h2>Delivery Details</h2>

      <h4>Drop off Information</h4>
      <Row className="mg-bottom-10">
        <Col>
          Production Kitchen:{" "}
          <input
            type="text"
            disabled
            style={{ border: "none", background: "#fff" }}
            ref={kitchen}
          />
        </Col>
        <Col>
          Signature Collected:{" "}
          {signatureImage.length > 0 ? (
            <a
              style={{
                color: "#0a58ca",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={handleShowSignature}
            >
              Yes
            </a>
          ) : (
            "No"
          )}
        </Col>
        <Col></Col>
      </Row>
      <Row className="mg-bottom-10">
        <Col>
          Customer Location:{" "}
          <input
            type="text"
            disabled
            style={{ border: "none", background: "#fff" }}
            ref={location}
          />
        </Col>
        <Col>
          Delivery Timestamp:{" "}
          <input
            type="text"
            disabled
            style={{ border: "none", background: "#fff" }}
            ref={dropOffTimestamp}
          />
        </Col>
        <Col></Col>
      </Row>
      <Row className="mg-bottom-10">
        <Col xs={2}>
          <labe1>Driver notes:</labe1>
        </Col>
        <Col xs={10}>
          <textarea
            disabled
            style={{ overflow: "hidden", width: "80%", resize: 'none' }}
            ref={driverNotes}
            
          />
        </Col>
      </Row>
      <h4>Shrink pickup summary</h4>
      <div className="ag-theme-alpine" style={{ height: "60%" }}>
        <AgGridReact
          ref={gridRef}
          rowSelection="single"
          defaultColDef={defaultColDef}
          rowData={productGridData}
          cacheQuickFilter={true}
          columnDefs={columnDefs}
        ></AgGridReact>
      </div>
      <Modal show={showGallery} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={viewerImages} showPlayButton={false} />
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the delivery item? (Note: This action will also attempt to reduce the shrink on the applied order. The data cannot be recovered)</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => {
            setSelectedDeliveryItem(null);
            handleClose();
            }}>
            NO
          </Button>
          <Button variant="danger" onClick={() => {
            deleteDeliveryItem(selectedDeliveryItem);
            handleClose();
            }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeliveryDetails;