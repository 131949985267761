import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API_URL from "../config";
import AuthContext from "../store/auth-context";
import { PRODUCTTYPES } from "../constants/Common";
import { DELETE, DisplayResults, GET, POST, PUT } from "../services/httpClient";
import { ADD_PRODUCT, EDIT_PRODUCT, PRODUCT_CREATED, PRODUCT_UPDATED } from "../constants/Messages";

const ProductDetails = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [heading, setHeading] = useState(ADD_PRODUCT);
  const [mode, setMode] = useState(1);
  const [productData, setProductData] = useState();
  const [validated, setValidated] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const name = useRef("");
  const type = useRef();
  const sequence = useRef();
  const barcode = useRef();

  const handleClose = () => {
    setShowProductModal(false);
  };

  const handleShow = () => setShowProductModal(true);

  const fetchProductHandler = useCallback(async (id) => {
    GET(
      `${API_URL}/api/v1/Product/GetProduct?productId=` + id,
      {},
      (response) => {
        if (DisplayResults(response) && response.statusCode != 204) {
          var data = response.result;
          name.current.value = data.name;
          type.current.value = data.productType;
          sequence.current.value = data.sequence;
          barcode.current.value = data.barcode;
    
          setProductData(data);
        }
      });
  }, []);

  const removeProductHandler = () => {
    DELETE(
      `${API_URL}/api/v1/Product/RemoveProduct/?id=` + productData.id,
      {},
      (response) => {
        handleClose();

        if (DisplayResults(response, "Deleted successfully")) {
          navigate("/products");
        }
      });
  };

  const saveProductCallback = (response) => {
    setIsSubmitting(false);

    const successMessage = mode === 1 ? PRODUCT_CREATED : PRODUCT_UPDATED;
    if (DisplayResults(response, successMessage)) {
      navigate("/products");
    }
  };

  const SaveDetailsHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      setIsSubmitting(true);
      var productDetails = {
        id: 0,
        name: name.current.value,
        productType: type.current.value,
        sequence: sequence.current.value === "" ? 0 : sequence.current.value,
        barcode: barcode.current.value === "" ? null : barcode.current.value,
      };

      if (mode == 1) {
        POST(
          `${API_URL}` + "/api/v1/Product/AddProduct",
          productDetails,
          saveProductCallback
        );
      } else {
        productDetails.id = productData.id;
        PUT(
          `${API_URL}` + "/api/v1/Product/UpdateProduct",
          productDetails,
          saveProductCallback
        );
      }
    }
  };

  useEffect(() => {
    if (params.productId) {
      setMode(2);
      setHeading(EDIT_PRODUCT);
      fetchProductHandler(params.productId);
    } else {
      setMode(1);
    }
  }, []);

  const typeList = PRODUCTTYPES.map((item, i) => {
    return (
      <option
        key={item + item.id}
        value={item.id}
        selected={productData && productData.productType == item.id}
      >
        {item.name}
      </option>
    );
  });

  return (
    <div className="root-block">
      <h2>{heading}</h2>
      <Form noValidate validated={validated}>
        <Row className="mg-bottom-10">
          <Col xs={2}>Name</Col>
          <Col xs={4}>
            <Form.Control
              type="text"
              placeholder="Enter name"
              maxLength={100}
              ref={name}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mg-bottom-10">
          <Col xs={2}>Type</Col>
          <Col xs={4}>
            <Form.Select aria-label="Default Role example" ref={type}>
              {typeList}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mg-bottom-10">
          <Col xs={2}>Sequence</Col>
          <Col xs={4}>
            <Form.Control
              type="number"
              min="0"
              placeholder="Enter sequence"
              ref={sequence}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter the sequence.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mg-bottom-10">
          <Col xs={2}>Barcode</Col>
          <Col xs={4}>
            <Form.Control
              type="number"
              min="1"
              placeholder="Enter barcode"
              ref={barcode}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "100px" }}>
          <Col xs={2}>
            {mode == 2 && (
              <Button
                className="button-standard"
                variant="outline-danger"
                onClick={handleShow}
                disabled={!authCtx.writeRights.includes("ProductList")}>
                Remove
              </Button>
            )}
          </Col>
          <Col xs={2}> </Col>

          <Col xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button 
            className="button-standard"
            variant="outline-dark">
              <Link to="/products">Close</Link>
            </Button>
          </Col>
          <Col xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="button-standard"
              variant="primary"
              type="button"
              onClick={SaveDetailsHandler}
              disabled={isSubmitting || !authCtx.writeRights.includes("ProductList")}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal show={showProductModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the product?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            NO
          </Button>
          <Button variant="danger" onClick={removeProductHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductDetails;
