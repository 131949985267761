import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import API_URL from "../config";
import Grid from "../components/Grid";
import { STATES } from "../constants/Common";
import AuthContext from "../store/auth-context";
import { DisplayResults, GET } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Users.css";

const CustomerLocationList = () => {
  const authCtx = useContext(AuthContext);
  const [gridData, setGridData] = useState(null);

  const userCellRenderer = (e) => {
    var userlink = "/customerlocationlist/" + e.data.Id;
    return (
      <Link className="user-link" to={userlink}>
        {e.data.Name}
      </Link>
    );
  };

  const [columnDefs] = useState([
    { field: "Name", flex: 2, cellRenderer: userCellRenderer },
    { field: "Address", flex: 2 },
    { field: "City", flex: 1 },
    { field: "State", flex: 1 },
    { field: "Zip", flex: 1 },
    { field: "Main Contact Phone", flex: 1 },
    { field: "Main Contact", flex: 2 },
    { field: "Kitchen", flex: 1 },
  ]);

  const getStateName = (stateId) => {
    return STATES.find((s) => s.id === stateId).name;
  };

  const getLocationsCallback = useCallback((response) => {
    var temp = [];
    if (DisplayResults(response, null)) {
      var data = response.result;
      data.forEach((d) => {
        var u = {
          Id: d.id,
          Name: d.name,
          Address: d.address,
          City: d.city,
          State: getStateName(d.stateId),
          Zip: d.zip,
          "Main Contact Phone": d.mainContactPhone,
          "Main Contact": d.mainContactName,
          "Kitchen": d.kitchenName,
        };
        temp.push(u);
      });
    }
    setGridData(temp);
  }, []);

  const fetchLocationsHandler = useCallback(async () => {
    try {
      GET(
        `${API_URL}/api/v1/Location/GetAllCustomerLocations`,
        {},
        getLocationsCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, [getLocationsCallback]);

  useEffect(() => {
    fetchLocationsHandler();
  }, [fetchLocationsHandler]);

  return (
    <div className="root-block">
      <Row style={{ float: "right" }}>
        {authCtx.writeRights.includes("LocationList") && 
        <Link className="user-link" to="/customerlocationlist/add">
          + Add Location
        </Link>}
      </Row>
      {gridData == null && (
          <Col>
            <div style={{ marginLeft: "50%", marginTop: "5%" }}>
              <Spinner animation="border" />
            </div>
            <div style={{ marginLeft: "48%", marginTop: "1%" }}>
              <h4 style={{ color: "#aaa" }}>Loading...</h4>
            </div>
          </Col>
        )}
        {gridData != null && (
          <Grid
          sortable={true}
          filter={true}
          data={gridData}
          columnDefs={columnDefs} />
        )}
    </div>
  );
};

export default CustomerLocationList;