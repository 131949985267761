import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API_URL from "../config";
import AuthContext from "../store/auth-context";
import ZoomControls from "../components/ZoomControls"
import { CHANGES_SAVED, IMAGES_DELETED } from "../constants/Messages"
import { DELETE, DisplayResults, GET, PUT } from "../services/httpClient";

const ProductionKitchenImageDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const [kitchenImageData, setKitchenImageData] = useState();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showImageZoom, setShowImageZoom] = useState(false);
    const [canSave, setCanSave] = useState(true); //Saw some issues with fast-clicking doing a double or triple save
    const imageDateControl = useRef();
    const submittedByNameControl = useRef();
    const reviewedControl = useRef(false);
    const reviewedDateControl = useRef("");
    const reviewedByNameControl = useRef("");

    useEffect(() => {
        fetchKitchenImage();
    }, []);

    const fetchKitchenImage = useCallback(async () => {
        GET(
        `${API_URL}/api/v1/Kitchen/GetKitchenImage?kitchenImageId=` + params.kitchenImageId,
        {},
        (response) => {
            if (DisplayResults(response)) {
                var data = response.result;
                imageDateControl.current.value = new Date(data.imageDate).toLocaleDateString();
                submittedByNameControl.current.value = data.submittedByName;
                reviewedControl.current.checked = data.reviewed;
                reviewedDateControl.current.value = data.reviewedDate === null ? "" : new Date(data.reviewedDate).toLocaleString();
                reviewedByNameControl.current.value = data.reviewedByName;
        
                setKitchenImageData(data);
            }
        });
    }, []);

    const updateKitchenImage = () => {
      if (!canSave) {
        return;
      }

      setCanSave(false);
      var currentImage = {
          id: kitchenImageData.id,
          kitchenId: kitchenImageData.kitchenId,
          reviewed: reviewedControl.current.checked,
          reviewedDate:  reviewedDateControl.current.value === "" ? null : new Date(reviewedDateControl.current.value)
          };

      PUT(
          `${API_URL}/api/v1/Kitchen/UpdateKitchenImage`,
          currentImage,
          (response) => {
              if (DisplayResults(response, CHANGES_SAVED)) {
                  navigate("/productionkitchen/" + kitchenImageData.kitchenId + "/kitchenimages");
              } else {
                setCanSave(true);
              }
          });
    }

    const removeKitchenImage = () => {
      if (!canSave) {
        return;
      }

      setCanSave(false);
      DELETE(
          `${API_URL}/api/v1/Kitchen/RemoveKitchenImage/?kitchenImageId=` + kitchenImageData.id,
          {},
          (response) => {
            if (DisplayResults(response, IMAGES_DELETED)) {
                navigate("/productionkitchen/" + kitchenImageData.kitchenId + "/kitchenimages");
            } else {
              setCanSave(true);
            }
          });
    }

  return (
<div className="root-block">
      <h2>Production Kitchen Image</h2>
      <Form>
        <Row>
          <Col xs={5}>
            <Row className="mg-bottom-10">
              <Col xs={4}>Image Date</Col>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  disabled={true}
                  ref={imageDateControl}
                />
              </Col>
            </Row>
            <Row className="mg-bottom-10">
              <Col xs={4}>Submitted By</Col>
              <Col xs={6}>
              <Form.Control
                  type="text"
                  disabled={true}
                  ref={submittedByNameControl}
                />
              </Col>
            </Row>
            <Row className="mg-bottom-10">
              <Col xs={4}>Reviewed</Col>
              <Col xs={6}>
                <Form.Check
                  ref={reviewedControl}
                  disabled={!authCtx.writeRights.includes("ManageKitchenImages")}
                  onChange={() => {
                    if (reviewedControl.current.checked) {
                      reviewedDateControl.current.value = new Date().toLocaleString();
                      reviewedByNameControl.current.value = authCtx.username;
                    } else {
                      reviewedDateControl.current.value = "";
                      reviewedByNameControl.current.value = "";
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className="mg-bottom-10">
              <Col xs={4}>Reviewed Date</Col>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  disabled={true}
                  ref={reviewedDateControl}
                />
              </Col>
            </Row>
            <Row className="mg-bottom-10">
              <Col xs={4}>Reviewed By</Col>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  disabled={true}
                  ref={reviewedByNameControl}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Image src={kitchenImageData?.imageUrl}
              style={{ maxWidth: "50%", maxHeight: "50%"}}
              onClick={() => {setShowImageZoom(true)}}/>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={2}>
            <Button
              className="button-standard"
              variant="outline-danger"
              onClick={() => {setShowDeleteConfirmation(true)}}
              disabled={!authCtx.writeRights.includes("ManageKitchenImages")}>
              Remove
            </Button>
          </Col>
          <Col xs={2}> </Col>
          <Col xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button 
            className="button-standard"
            variant="outline-dark">
              <Link to={"/productionkitchen/" + params.kitchenId + "/kitchenimages"}>Close</Link>
            </Button>
          </Col>
          <Col xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="button-standard"
              variant="primary"
              type="button"
              disabled={!canSave || !authCtx.writeRights.includes("ManageKitchenImages")}
              onClick={updateKitchenImage}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal show={showDeleteConfirmation} onHide={() => {setShowDeleteConfirmation(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the image?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => {setShowDeleteConfirmation(false)}}>
            No
          </Button>
          <Button variant="danger" onClick={removeKitchenImage}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>      
      <Modal 
        show={showImageZoom}
        onHide={() => {setShowImageZoom(false)}}
        centered={true}
        >
        <Modal.Body>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <ZoomControls />
                <TransformComponent>
                <Image src={kitchenImageData?.imageUrl}
                      style={{ maxHeight: "calc(100vh - 130px)"}}
                      />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductionKitchenImageDetails;