import { useCallback, useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import API_URL from "../config";
import PageCard from "../components/PageCard";
import AuthContext from "../store/auth-context";
import { FilterToSaturdays } from "../services/DateHelper"
import { DisplayResults, GET } from "../services/httpClient";
import "./Dashboard.css";

const Dashboard = () => {
  const authCtx = useContext(AuthContext);
  const [showExportModal, setShowExportModal] = useState(false);
  const [kitchens, setKitchens] = useState([]);
  const selectedKitchen = useRef();
  const [endDate, setEndDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const handleShow = () => {
    if (!canRead("OrderExport")) {
      return;
    }
    
    fetchKitchenHandler();
    setShowExportModal(true);
  };

  const handleClose = () => {
    setShowExportModal(false);
  };

  const fetchExportData = (kitchenId, orderDate) => {
    try {
      setShowLoader(true);
      var formattedDate = orderDate.getFullYear() + '-' + (orderDate.getMonth() + 1) + '-' + orderDate.getDate()
      GET(
        `${API_URL}` +
          "/api/v1/Order/GetOrderExport?" +
          "kitchenId=" +
          kitchenId +
          "&orderDate=" +
          formattedDate,
        {},        
        (response) => {
          if (DisplayResults(response, null)) {
            saveExportFile(response.result);      
          }

          setShowExportModal(false);
          setShowLoader(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const saveExportFile = (fileContents) => {
    const blob = new Blob([fileContents], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "production-data.csv";
    link.href = url;
    link.click();
  };

  // useCallback can be called each time state changes
  const fetchKitchenHandler = useCallback(async () => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Kitchen/GetActiveKitchens",
        {},
        getKitchenDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getKitchenDataCallback = (response) => {
    var temp = [];
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }

    setKitchens(temp);
  };

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.id}
          selected={item.id == 0}
        >
          {item.name}
        </option>
      );
    });

  useEffect(() => {
    const today = new Date();
    setEndDate(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (7 - today.getDay())
      )
    );
  }, []);

  function canRead(permission) {
    return authCtx.readRights.includes(permission)
  }

  return (
    <Container fluid="xl" style={{ paddingTop: "3%" }}>
      <Row>
        <Container fluid>
          <Row className="page-block text-center">
            <Col>
              <PageCard 
                header="Users" 
                desc="Manage the users" 
                link="/users"
                enabled={canRead("UserList")}
              />
            </Col>
            <Col>
              <PageCard
                header="Production Locations"
                desc="Manage production facilities"
                link="/productionkitchen"
                enabled={canRead("KitchenList")}
              />
            </Col>
            <Col>
              <PageCard
                header="Customer Locations"
                desc="Manage customer locations"
                link="/customerlocationlist"
                enabled={canRead("LocationList")}
              />
            </Col>
          </Row>
          <Row className="page-block text-center">
            <Col>
              <PageCard
                header="Products"
                desc="Manage the product list"
                link="/products"
                enabled={canRead("ProductList")}
              />
            </Col>
            <Col>
              <PageCard
                header="Prices"
                desc="Manage prices and costs"
                link="/pricelist"
                enabled={canRead("PriceList")}
              />
            </Col>
            <Col>
              <PageCard
                header="Orders"
                desc="Manage orders"
                link="/orders"
                enabled={canRead("OrderList")}
              />
            </Col>
          </Row>
          <Row className="page-block text-center">
            <Col>
              <PageCard
                header="Sales Report"
                desc="View sales history"
                link="/salesreport"
                enabled={canRead("SalesReport")}
              />
            </Col>
            <Col>
              <PageCard
                header="Product Report"
                desc="View product history"
                link="/productreport"
                enabled={canRead("ProductReport")}
              />
            </Col>
            <Col>
              <PageCard
                header="Forecasting Report"
                desc="Predict future order quantities"
                link="/forecastingreport"
                enabled={canRead("ForecastingReport")}
              />
            </Col>
          </Row>
          <Row className="page-block text-center">
            <Col>
              <PageCard
                header="Order Export"
                desc="Export order data for production"
                onClick={handleShow}
                enabled={canRead("OrderExport")}
              />
            </Col>
          </Row>
        </Container>
      </Row>
      <Modal show={showExportModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Export weekly production orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Production Location</Form.Label>
          <Form.Select
            aria-label="Default label"
            ref={selectedKitchen}
          >
            {kitchenList}
          </Form.Select>
          <Form.Label>Order Week</Form.Label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}            
            filterDate={FilterToSaturdays}
            dateFormat="MM/dd/yyyy"
            className="date-picker"
          />
        </Modal.Body>
        <Modal.Footer>
          {showLoader && (
            <Col>
              <Spinner animation="border" />
            </Col>
          )}
          <Button 
            className="button-standard"
            variant="secondary"
            onClick={handleClose}>
            Close
          </Button>
          <Button 
            className="button-standard"
            variant="primary"
            onClick={() => {
              fetchExportData(
                selectedKitchen.current.selectedOptions[0].attributes["data-keyid"].value,
                endDate
              );
            }}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

{
  /* <Col
          lg={12}
          //className="align-self-center"
          style={{ background: "red" }}
        >
          One of three columns
        </Col> */
}

export default Dashboard;
