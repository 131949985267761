import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API_URL from "../config";
import { STATES } from "../constants/Common";
import AuthContext from "../store/auth-context";
import { ValidateEmails } from "../services/Validation";
import { DELETE, DisplayResults, GET, POST, PUT } from "../services/httpClient";
import { ADD_LOCATION, EDIT_LOCATION, LOCATION_CREATED, LOCATION_UPDATED } from "../constants/Messages";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Users.css";

const LocationDetails = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(1); // 1 for create, 2 for edit
  const [heading, setHeading] = useState(ADD_LOCATION);
  const [validated, setValidated] = useState(false);
  const [kitchens, setKitchens] = useState([]);
  const [locationData, setlocationData] = useState({});
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [locationImages, setLocationImages] = useState([]);
  const [viewerImages, setviewerImages] = useState([]);
  const [priceList, setPriceList] = useState([]);

  const name = useRef("");
  const address = useRef("");
  const city = useRef("");
  const state = useRef("");
  const zip = useRef();
  const vendorNumber = useRef();
  const mainContactName = useRef();
  const mainContactPhone = useRef();
  const mainContactEmail = useRef();
  const billingContactName = useRef();
  const billingContactPhone = useRef();
  const billingContactEmail = useRef();
  const kitchen = useRef(1);
  const selectedPriceList = useRef();
  const deliveryInstructions = useRef();
  const requireSignature = useRef();
  const confirmationEmail = useRef();
  const forecastOrders = useRef();
  const minimumQuantityDefault = useRef();
  const minimumQuantityValue = useRef();
  const processingFeeDefault = useRef();
  const processingFeeValue = useRef();
  const [isMainContactPhoneValid, setIsMainContactPhoneValid] = useState(true);
  const [isBillingContactPhoneValid, setIsBillingContactPhoneValid] = useState(true);
  const [isMainContactEmailValid, setIsMainContactEmailValid] = useState(true);
  const [isBillingContactEmailValid, setIsBillingContactEmailValid] = useState(true);

  const handleClose = () => {
    setShowLocationModal(false);
  };
  const handleShow = () => setShowLocationModal(true);

  const validatePhoneNumber = (value) => {
    const regex = /^[\d-]{1,50}( x\d+)?$/;
    return regex.test(value);
  };

  const handleMainPhoneNumberChange = () => {
    const inputValue = mainContactPhone.current.value;
    setIsMainContactPhoneValid(
      validatePhoneNumber(inputValue) || inputValue === ""
    );
  };

  const handleBillingPhoneNumberChange = () => {
    const inputValue = billingContactPhone.current.value;
    setIsBillingContactPhoneValid(
      validatePhoneNumber(inputValue) || inputValue === ""
    );
  };

  const handleMainEmailChange = () => {
    const inputValue = mainContactEmail.current.value;
    setIsMainContactEmailValid(ValidateEmails(inputValue) || inputValue === "");
  };

  const handleBillingEmailChange = () => {
    const inputValue = billingContactEmail.current.value;
    setIsBillingContactEmailValid(ValidateEmails(inputValue) || inputValue === "");
  };

  const getLocationDataCallback = (response) => {
    if (DisplayResults(response)) {
      var tempLocationImages = [];
      var data = response.result;
      name.current.value = data.name;
      address.current.value = data.address;
      city.current.value = data.city;
      zip.current.value = data.zip;
      vendorNumber.current.value = data.vendorNumber ?? "";
      mainContactName.current.value = data.mainContactName ?? "";
      mainContactPhone.current.value = data.mainContactPhone ?? "";
      mainContactEmail.current.value = data.mainContactEmail ?? "";
      billingContactName.current.value = data.billingContactName;
      billingContactPhone.current.value = data.billingContactPhone ?? "";
      billingContactEmail.current.value = data.billingContactEmail ?? "";
      kitchen.current.value = data.kitchen;
      deliveryInstructions.current.value = data.deliveryInstructions ?? "";
      requireSignature.current.checked = data.requireSignature;
      confirmationEmail.current.checked = data.confirmationEmail;
      forecastOrders.current.checked = data.forecastOrders;
      minimumQuantityDefault.current.checked = data.minimumOrderQuantity === null;
      minimumQuantityValue.current.value = data.minimumOrderQuantity;
      minimumQuantityValue.current.disabled = minimumQuantityDefault.current.checked;
      processingFeeDefault.current.checked = data.processingFee === null;
      processingFeeValue.current.value = data.processingFee;
      processingFeeValue.current.disabled = processingFeeDefault.current.checked;
      // The price list selector might be null if the user lacked permissions to populate it
      if (selectedPriceList.current != null) {
        selectedPriceList.current.value = data.priceListId;
      }
      setlocationData(data);

      data.locationImages.map((d) => {
        var i = {
          original: d.imageUrl,
          thumbnail: d.imageUrl,
          description: d.notes,
        };
        tempLocationImages.push(i);
      });
      setLocationImages(tempLocationImages);
    }
  };

  const fetchLocationHandler = useCallback(async (id) => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Location/GetLocation?id=" + id,
        {},
        getLocationDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const saveUserCallback = (response) => {
    const successMessage = mode === 1 ? LOCATION_CREATED : LOCATION_UPDATED;
    if (DisplayResults(response, successMessage)) {
      navigate("/customerlocationlist");
    }
  };

  const saveLocationHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (
      form.checkValidity() === false ||
      isMainContactPhoneValid != true ||
      isBillingContactPhoneValid != true ||
      isMainContactEmailValid != true ||
      isBillingContactEmailValid != true
    ) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      var locationInfo = {
        id: locationData.id ?? 0,
        name: name.current.value,
        address: address.current.value,
        city: city.current.value,
        stateId:
          state.current.selectedOptions[0].attributes["data-keyid"].value,
        state: state.current.selectedOptions[0].label,
        zip: zip.current.value,
        vendorNumber: vendorNumber.current.value,
        mainContactName: mainContactName.current.value,
        mainContactPhone: mainContactPhone.current.value,
        mainContactEmail: mainContactEmail.current.value,
        billingContactName: billingContactName.current.value,
        billingContactPhone: billingContactPhone.current.value,
        billingContactEmail: billingContactEmail.current.value,
        kitchenId:
          kitchen.current.selectedOptions[0].attributes["data-keyid"].value,
        deliveryInstructions: deliveryInstructions.current.value,
        requireSignature: requireSignature.current.checked,
        confirmationEmail: confirmationEmail.current.checked,
        forecastOrders: forecastOrders.current.checked,
        minimumOrderQuantity: minimumQuantityDefault.current.checked ? null : minimumQuantityValue.current.value,
        processingFee: processingFeeDefault.current.checked ? null : processingFeeValue.current.value,
        active: true,
        priceListId: selectedPriceList.current == null ? locationData.priceListId : selectedPriceList.current.value,
      };

      if (mode == 1) {
        POST(
          `${API_URL}` + "/api/v1/Location/AddLocation",
          locationInfo,
          saveUserCallback
        );
      } else {
        PUT(
          `${API_URL}` + "/api/v1/Location/UpdateLocation",
          locationInfo,
          saveUserCallback
        );
      }
    }
  };

  const removeLocationHandler = () => {
    DELETE(
      `${API_URL}` + "/api/v1/Location/RemoveLocation/?id=" + locationData.id,
      {},
      (response) => {
        handleClose();

        if (DisplayResults(response, "Deleted")) {
          navigate("/customerlocationlist");
        }
      }
    );
  };

  const copyContactHandler = () => {
    billingContactName.current.value = mainContactName.current.value;
    billingContactPhone.current.value = mainContactPhone.current.value;
    billingContactEmail.current.value = mainContactEmail.current.value;
  };

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.id}
          selected={locationData.kitchenId == item.id}
        >
          {item.name}
        </option>
      );
    });

  const stateList = STATES.map((item, i) => {
    return (
      <option
        key={item + item.id}
        value={item.id}
        data-keyid={item.id}
        selected={locationData.stateId == item.id}
      >
        {item.name}
      </option>
    );
  });

  const pricesList =
    priceList.length > 0 &&
    priceList.map((item, i) => {
      return (
        <option
          key={i + item.name}
          value={item.id}
          data-keyid={item.name}
          // selected={
          //   priceListData && priceListData.priceList.currentVersion == item.name
          // }
        >
          {item.name}
        </option>
      );
    });

  useEffect(() => {
    fetchKitchenHandler();
    fetchPriceList();

  }, []);

  const fetchKitchenHandler = useCallback(async () => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Kitchen/GetAllKitchens",
        {},
        getKitchenDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getKitchenDataCallback = (response) => {
    var temp = [];
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }
    setKitchens(temp);
  };

  const fetchPriceList = useCallback(async () => {
    if (!authCtx.readRights.includes("PriceList")) {
      initializeScreen();
      return;
    }

    try {
      GET(`${API_URL}/api/v1/Prices/GetAllPriceLists`,
       {},
       (response) => {
        if (DisplayResults(response)) {
          var data = response.result;
          setPriceList(data);
          initializeScreen();
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const initializeScreen = () => {
    if (params.locationId) {
      setMode(2);
      setHeading(EDIT_LOCATION);
      fetchLocationHandler(params.locationId);
    } else {
      setMode(1);
    }
  }

  const handleCloseGallery = () => {
    setShowGallery(false);
  };

  const handleShowLocationImages = () => {
    setviewerImages(locationImages);
    setShowGallery(true);
  };

  return (
    <div className="root-block">
      <h2>{heading}</h2>
      <Form noValidate validated={validated} onSubmit={saveLocationHandler}>
        <Row>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Name</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  maxLength={100}
                  ref={name}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a name.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Address</Form.Label>
              </Col>
              <Col xs={7}>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  maxLength={500}
                  ref={address}
                ></textarea>
                <Form.Control.Feedback type="invalid">
                  Please enter address.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>City</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  maxLength={100}
                  ref={city}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter city.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>State</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Select aria-label="Default Role example" ref={state}>
                  {stateList}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  Please choose a state.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Zip</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter zip"
                  maxLength={50}
                  ref={zip}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the zip code.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Vendor #</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter vendor#"
                  ref={vendorNumber}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Default Production Kitchen</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Select
                  aria-label="Default label"
                  ref={kitchen}
                  //onChange={kitchenChangeHandler}
                >
                  {kitchenList}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Delivery Instructions</Form.Label>
              </Col>
              <Col xs={7}>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  ref={deliveryInstructions}
                ></textarea>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4}>
                <Form.Check
                  type="checkbox"
                  id="checkbox"
                  label="Require Signature"
                  ref={requireSignature}
                />
              </Col>
              <Col xs={4}>
                <Form.Check
                  type="checkbox"
                  id="checkbox"
                  label="Confirmation Email"
                  ref={confirmationEmail}
                />
              </Col>
              <Col xs={4}>
                <Form.Check
                  type="checkbox"
                  id="checkbox"
                  label="Forecast Orders"
                  ref={forecastOrders}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              {authCtx.readRights.includes("LocationImages") && 
                  <Row className="mb-3">
                    <Col xs={6}>
                      <Link className="user-link" onClick={handleShowLocationImages}>
                        Location Images
                      </Link>
                    </Col>
                  </Row>
                }
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <Form.Label>Main Contact</Form.Label>
              </Col>
            </Row>
            <div
              style={{
                border: "1px solid #ccc",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    maxLength={200}
                    ref={mainContactName}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Phone</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    ref={mainContactPhone}
                    maxLength={50}
                    onChange={handleMainPhoneNumberChange}
                    placeholder="Enter phone number"
                    isInvalid={
                      !isMainContactPhoneValid &&
                      mainContactPhone.current.value != ""
                    }
                  />
                  {!isMainContactPhoneValid &&
                    mainContactPhone.current.value !== "" && (
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid phone number.
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    ref={mainContactEmail}
                    onChange={handleMainEmailChange}
                    placeholder="Enter email"
                    maxLength={1000}
                    isInvalid={
                      !isMainContactEmailValid &&
                      mainContactEmail.current.value != ""
                    }
                  />
                  {!isMainContactEmailValid &&
                    mainContactEmail.current.value !== "" && (
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email.
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs={12} style={{ textAlign: "center", marginTop: "10px" }}>
                <Button 
                  variant="dark"
                  onClick={copyContactHandler}>
                  V Copy V
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Label>Billing Contact</Form.Label>
              </Col>
            </Row>
            <div
              style={{
                border: "1px solid #ccc",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    maxLength={200}
                    ref={billingContactName}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Phone</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    ref={billingContactPhone}
                    onChange={handleBillingPhoneNumberChange}
                    placeholder="Enter phone number"
                    maxLength={50}
                    isInvalid={
                      !isBillingContactPhoneValid &&
                      billingContactPhone.current.value !== ""
                    }
                  />
                  {!isBillingContactPhoneValid &&
                    billingContactPhone.current.value !== "" && (
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid phone number.
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    ref={billingContactEmail}
                    onChange={handleBillingEmailChange}
                    placeholder="Enter email"
                    maxLength={1000}
                    isInvalid={
                      !isBillingContactEmailValid &&
                      billingContactEmail.current.value !== ""
                    }
                  />
                  {!isBillingContactEmailValid &&
                    billingContactEmail.current.value !== "" && (
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email.
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Row>
            </div>
            <br/>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Minimum Order</Form.Label>
              </Col>
              <Col xs={2}>
              <Form.Check
                  type="checkbox"
                  id="defaultMinimumOrder"
                  label="Use default"
                  ref={minimumQuantityDefault}
                  onClick={() => {
                    minimumQuantityValue.current.value = null;
                    minimumQuantityValue.current.disabled = minimumQuantityDefault.current.checked
                  }}
                />
              </Col>
              <Col xs={4}>
                <Form.Control
                  type="number"
                  placeholder="0 = no minimum"
                  ref={minimumQuantityValue}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Processing Fee</Form.Label>
              </Col>
              <Col xs={2}>
              <Form.Check
                  type="checkbox"
                  id="defaultProcessingFee"
                  label="Use default"
                  ref={processingFeeDefault}
                  onClick={() => {
                    processingFeeValue.current.value = null;
                    processingFeeValue.current.disabled = processingFeeDefault.current.checked
                  }}
                />
              </Col>
              <Col xs={4}>
                <Form.Control
                  type="number"
                  step={.01}
                  placeholder="0.00% = no fee"
                  ref={processingFeeValue}
                />
              </Col>
            </Row>
            {authCtx.readRights.includes("PriceList") && 
              <Row className="mb-3">
                <Col xs={3}>
                  <Form.Label>Price List</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Select aria-label="PriceList" ref={selectedPriceList}>
                    {pricesList}
                  </Form.Select>
                </Col>
              </Row>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
            <Row>
              <Col xs={2}>
                 <Button
                  className="button-standard"
                  variant="outline-danger"
                  onClick={handleShow}
                  disabled={!authCtx.writeRights.includes("LocationList")}>
                  Remove
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <Row>
              <Col xs={6}></Col>
              <Col xs={6}>
                <Button 
                  className="button-standard"
                  variant="outline-dark"
                  style={{ marginRight: "15px" }}>
                  <Link to="/customerlocationlist">Close</Link>
                </Button>
                <Button 
                  className="button-standard"
                  variant="primary"
                  type="submit"
                  disabled={!authCtx.writeRights.includes("LocationList")}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal show={showLocationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the location?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            NO
          </Button>
          <Button variant="danger" onClick={removeLocationHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showGallery} onHide={handleCloseGallery}>
        <Modal.Header closeButton>
          <Modal.Title>Location Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={viewerImages} showPlayButton={false} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LocationDetails;
