import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import API_URL from "../config";
import Grid from "../components/Grid";
import { DisplayResults, GET } from "../services/httpClient";
import { FilterToSaturdays, FindNextSaturday } from "../services/DateHelper"
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const ForecastingReport = () => {
  const [gridData, setGridData] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const [locations, setLocations] = useState([]);
  const [orderDate, setOrderDate] = useState(FindNextSaturday());
  const [columnDefs, setColumnDefs] = useState([]);
  const selectedKitchen = useRef();
  const selectedLocation = useRef();
  const defaultZeroText = "0/0/0/0";
  

  useEffect(() => {
    fetchKitchenHandler();
  }, [])

  const fetchKitchenHandler = useCallback(async () => {
      GET(
        `${API_URL}/api/v1/Kitchen/GetActiveKitchens`,
        {},
        getKitchenDataCallback
      );
  }, [])

  const getKitchenDataCallback = (response) => {
    var temp = []

    if (DisplayResults(response)) {
      var data = response.result;
      data.forEach((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }

    setKitchens(temp);
    if (temp.length > 0) {
        fetchLocationHandler(temp[0].id);
    }
  }

  const fetchLocationHandler = (id) => {
    GET(
        `${API_URL}` + "/api/v1/Location/GetActiveLocations?kitchenId=" + id,
        {},
        getLocationDataCallback
    );
  }

  const getLocationDataCallback = (response) => {
    var temp = [];

  if (DisplayResults(response)) {
      var data = response.result;
      if (data != null) {
        data.map((d) => {
          var u = {
            id: d.id,
            name: d.name,
          };
          temp.push(u);
        });
      }
    }

    setLocations(temp);
  }

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.id}
          selected={item.id == 0}
        >
          {item.name}
        </option>
      );
    })

  const locationList =
    locations.length > 0 &&
    locations.map((item, i) => {
      return (
        <option
          key={i + item.name}
          value={item.id}
          label={item.name}
          data-keyid={item.id}
        />
      );
    })

  const runReportHandler = () => {
    fetchReportData(
      selectedKitchen.current.selectedOptions[0].attributes["data-keyid"].value,
      selectedLocation.current.selectedOptions[0].attributes["data-keyid"].value,
      orderDate.getFullYear() + '-' + (orderDate.getMonth() + 1) + '-' + orderDate.getDate()
    );
  }

  const fetchReportData = (kitchenId, locationId, orderDate) => {
      GET(
        `${API_URL}/api/v1/Report/GetForecastingReport?` +
          "kitchenId=" + kitchenId +
          "&locationId=" + locationId +
          "&orderDate=" + orderDate,
        {},
        getDataCallback
      );
  }

  const getDataCallback = (response) => {
    var temp = [];

    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          Product: d.product,
          SunOrder: d.sunOrder,
          SunShrink: d.sunShrink,
          SunForecast: d.sunForecast,
          MonOrder: d.monOrder,
          MonShrink: d.monShrink,
          MonForecast: d.monForecast,
          TueOrder: d.tueOrder,
          TueShrink: d.tueShrink,
          TueForecast: d.tueForecast,
          WedOrder: d.wedOrder,
          WedShrink: d.wedShrink,
          WedForecast: d.wedForecast,
          ThuOrder: d.thuOrder,
          ThuShrink: d.thuShrink,
          ThuForecast: d.thuForecast,
          FriOrder: d.friOrder,
          FriShrink: d.friShrink,
          FriForecast: d.friForecast,
          SatOrder: d.satOrder,
          SatShrink: d.satShrink,
          SatForecast: d.satForecast,
          PreviousTotal: d.previousTotalOrder,
          ForecastTotal: d.forecastTotal,
          Profit: d.profitPerUnit
        };
        temp.push(u);
      });
    }
    
    determineGridColumns(temp);
    setGridData(temp);
  }

  const determineGridColumns = (gridData) => {
    var colTemp = [];

    colTemp.push({ field: "Product", flex: 2 });

    // Build the columns dynamically to avoid showing all zero columns
    if (gridData.some(function (record) { return (record.SunOrder !== defaultZeroText || record.SunShrink !== defaultZeroText || record.SunForecast > 0) })) {
        colTemp.push({ field: "SunOrder", flex: 1 });
        colTemp.push({ field: "SunShrink", flex: 1 });
        colTemp.push({ field: "SunForecast", flex: 1 });
    }
    
    if (gridData.some(function (record) { return (record.MonOrder !== defaultZeroText || record.MonShrink !== defaultZeroText || record.MonForecast > 0) })) {
        colTemp.push({ field: "MonOrder", flex: 1 });
        colTemp.push({ field: "MonShrink", flex: 1 });
        colTemp.push({ field: "MonForecast", flex: 1 });
    }

    if (gridData.some(function (record) { return (record.TueOrder !== defaultZeroText || record.TueShrink !== defaultZeroText || record.TueForecast > 0) })) {
        colTemp.push({ field: "TueOrder", flex: 1 });
        colTemp.push({ field: "TueShrink", flex: 1 });
        colTemp.push({ field: "TueForecast", flex: 1 });
    }
    
    if (gridData.some(function (record) { return (record.WedOrder !== defaultZeroText || record.WedShrink !== defaultZeroText || record.WedForecast > 0) })) {
        colTemp.push({ field: "WedOrder", flex: 1 });
        colTemp.push({ field: "WedShrink", flex: 1 });
        colTemp.push({ field: "WedForecast", flex: 1 });
    }

    if (gridData.some(function (record) { return (record.ThuOrder !== defaultZeroText || record.ThuShrink !== defaultZeroText || record.ThuForecast > 0) })) {
        colTemp.push({ field: "ThuOrder", flex: 1 });
        colTemp.push({ field: "ThuShrink", flex: 1 });
        colTemp.push({ field: "ThuForecast", flex: 1 });
    }

    if (gridData.some(function (record) { return (record.FriOrder !== defaultZeroText || record.FriShrink !== defaultZeroText || record.FriForecast > 0) })) {
        colTemp.push({ field: "FriOrder", flex: 1 });
        colTemp.push({ field: "FriShrink", flex: 1 });
        colTemp.push({ field: "FriForecast", flex: 1 });
    }

    if (gridData.some(function (record) { return (record.SatOrder !== defaultZeroText || record.SatShrink !== defaultZeroText || record.SatForecast > 0) })) {
        colTemp.push({ field: "SatOrder", flex: 1 });
        colTemp.push({ field: "SatShrink", flex: 1 });
        colTemp.push({ field: "SatForecast", flex: 1 });
    }

    colTemp.push(
    {
      field: "Profit",
      flex: 1,
      valueFormatter: (params) => {
        return "$" + params.value.toFixed(2);
      },
    });

    setColumnDefs(colTemp);
  }

  return (
    <div className="root-block">
      <h2>Forecasting Report</h2>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>Production Kitchen</Form.Label>
        </Col>
        <Col xs={2}>
          <Form.Select
            aria-label="Default label"
            ref={selectedKitchen}
            onChange={() => { fetchLocationHandler(selectedKitchen.current.value) }}
          >
            {kitchenList}
          </Form.Select>
        </Col>
        <Col xs={1}>
          <Form.Label>Order Date</Form.Label>
        </Col>
        <Col xs={2}>
            <DatePicker
              selected={orderDate}
              onChange={(date) => setOrderDate(date)}
              filterDate={FilterToSaturdays}
              placeholderText="Select a Saturday"
              dateFormat="MM/dd/yyyy"
              className="date-picker"
            />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Label>Customer Location</Form.Label>
        </Col>
        <Col xs={2}>
          <Form.Select
            aria-label="Default label"
            ref={selectedLocation}
          >
            {locationList}
          </Form.Select>
        </Col>
      </Row>
      <Grid
        showExportButton={true}
        runFunction={runReportHandler}
        sortable={true}
        filter={true}
        data={gridData}
        columnDefs={columnDefs}
        height="64%"
      />
    </div>
  );
};

export default ForecastingReport;
