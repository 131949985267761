import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API_URL from "../config";
import { STATES } from "../constants/Common";
import AuthContext from "../store/auth-context";
import { DELETE, DisplayResults, GET, POST, PUT } from "../services/httpClient";
import { ADD_KITCHEN, CHANGES_SAVED, EDIT_KITCHEN } from "../constants/Messages";
import "./Users.css";

const ProductionKitchenDetails = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(1); // 1 for create, 2 for edit
  const [heading, setHeading] = useState(ADD_KITCHEN);
  const [kitchenData, setKitchenData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const name = useRef("");
  const address = useRef("");
  const city = useRef("");
  const state = useRef("");
  const zip = useRef();

  const handleClose = () => {
    setShowConfirmationDialog(false);
  };

  const handleShow = () => {
    setShowConfirmationDialog(true);
  };

  const saveKitchenHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (mode == 2) {
        kitchenData.name = name.current.value;
        kitchenData.address = address.current.value;
        kitchenData.city = city.current.value;
        kitchenData.stateId = state.current.selectedOptions[0].attributes["data-keyid"].value;
        kitchenData.zip = zip.current.value;

          PUT(
          `${API_URL}/api/v1/Kitchen/UpdateKitchen`,
          kitchenData,
          (response) => {
            if (DisplayResults(response, CHANGES_SAVED)) {
              navigate("/productionkitchen");
            }
          });
      } else {
        try {
          POST(
            `${API_URL}/api/v1/Kitchen/AddKitchen`,
            {
              name: name.current.value,
              address: address.current.value,
              city: city.current.value,
              stateId: state.current.selectedOptions[0].attributes["data-keyid"].value,
              zip: zip.current.value,
            },
            (response) => {
              const errorMessage = response.statusCode === 300 ? "Please enter a unique name" : null;
              if (DisplayResults(response, "New Kitchen Added", errorMessage)) {
                navigate("/productionkitchen");
              }
            });
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const statesList = STATES.map((item, i) => {
    return (
      <option
        key={item.id + item.name}
        value={item.name}
        data-keyid={item.id}
        selected={kitchenData && kitchenData.stateId == item.id}
      >
        {item.name}
      </option>
    );
  });

  const getDataCallback = (response) => {
    if (DisplayResults(response)) {
      var data = response.result;
      name.current.value = data.name;
      address.current.value = data.address;
      city.current.value = data.city;
      zip.current.value = data.zip;
      setKitchenData(data);
    }
  };

  const fetchKitchenHandler = useCallback(async (id) => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Kitchen/GetProductionKitchen?id=" + id,
        {},
        getDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const removeKitchenHandler = () => {
    try {
      DELETE(
        `${API_URL}` + "/api/v1/Kitchen/RemoveKitchen?id=" +
          params.kitchenId,
        {},
        (response) => {
          handleClose();

          if (DisplayResults(response, "Deleted Successfully", "The Kitchen cannot be deleted when it is associated with other data.")) {
            navigate("/productionkitchen");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (params.kitchenId) {
      setMode(2);
      setHeading(EDIT_KITCHEN);
      fetchKitchenHandler(params.kitchenId);
    } else {
      setMode(1);
    }
  }, []);

  return (
    <div className="root-block">
      <h2>{heading}</h2>
      <Form noValidate validated={validated} onSubmit={saveKitchenHandler}>
        <Row>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Name</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  maxLength={100}
                  ref={name}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a name.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Address</Form.Label>
              </Col>
              <Col xs={7}>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  ref={address}
                  maxLength={500}
                ></textarea>
                <Form.Control.Feedback type="invalid">
                  Please enter address.
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>City</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  maxLength={100}
                  ref={city}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter city.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>State</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Select aria-label="Default Role example" ref={state}>
                  {statesList}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>Zip</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter zip"
                  maxLength={50}
                  ref={zip}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the zip code.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}>
                {authCtx.readRights.includes("ManageKitchenImages") && 
                <Link className="user-link" to={"/productionkitchen/" + params.kitchenId + "/kitchenimages"} >
                  Kitchen Images
                </Link>}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={7}>
            {mode == 2 && (
              <Row>
                <Col xs={2}>
                  <Button 
                    className="button-standard"
                    variant="outline-danger"
                    onClick={handleShow}
                    disabled={!authCtx.writeRights.includes("KitchenList")}>
                    Remove
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={5}>
            <Row>
              <Col xs={3}></Col>
              <Col xs={3}>
                <Button 
                  className="button-standard"
                  variant="outline-dark"
                  style={{ marginRight: "15px" }}>
                  <Link to="/productionkitchen">Close</Link>
                </Button>                
              </Col>
              <Col xs={3}>
                <Button 
                  className="button-standard"
                  variant="primary"
                  type="Save"
                  disabled={!authCtx.writeRights.includes("KitchenList")}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal show={showConfirmationDialog} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the kitchen?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            NO
          </Button>
          <Button variant="danger" onClick={removeKitchenHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductionKitchenDetails;
