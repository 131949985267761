import React, { useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Grid.css";

const Grid = (props) => {
  const gridRef = useRef();
  const defaultColDef = {
    sortable: props.sortable,
    filter: props.filter,
    wrapHeaderText: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 200,
    },
  };

  const handleExport = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const handleRun = () => {
    props.runFunction();
  };

  const onFilterTextBoxChanged = () => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: props.height != null ? props.height : "85%" }}
    >
      <Row style={{ paddingTop: "10px" }}>
        <Col xs={2}>
          <input
            type="text"
            className="search-box"
            id="filter-text-box"
            placeholder="Filter..."
            onInput={onFilterTextBoxChanged}
          />
        </Col>

        <Col xs={8}></Col>
        <Col xs={1}>
          {props.showExportButton && (
            <Button
              className="button-standard"
              variant="primary"
              onClick={handleExport}
              style={{ width: "70px" }}
            >
              Export
            </Button>
          )}
        </Col>
        <Col xs={1} style={{ textAlign: "right" }}>
          {props.runFunction != null && (
            <Button
              className="button-standard"
              variant="primary"
              onClick={handleRun}
              style={{ width: "70px" }}
            >
              Run
            </Button>
          )}
        </Col>
      </Row>
      {props.data == null && (
        <Col>
          <div style={{ marginLeft: "50%", marginTop: "5%" }}>
            <Spinner animation="border" />
          </div>
          <div style={{ marginLeft: "48%", marginTop: "1%" }}>
            <h4 style={{ color: "#aaa" }}>Loading...</h4>
          </div>
        </Col>
      )}
      {props.data != null && (
        <AgGridReact
          ref={gridRef}
          rowSelection="single"
          defaultColDef={defaultColDef}
          rowData={props.data}
          cacheQuickFilter={true}
          columnDefs={props.columnDefs}
        ></AgGridReact>
      )}
    </div>
  );
};

export default Grid;
