import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import API_URL from "../config";
import Grid from "../components/Grid";
import { DisplayResults, GET } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const KitchenImageList = () => {
  const [visibleGridData, setVisibleGridData] = useState(null);
  const [fullGridData, setFullGridData] = useState(null);
  const params = useParams();
  const showReviewed = useRef(false);

  const kitchenImageCellRenderer = (e) => {
    var link = "/productionkitchen/" + params.kitchenId + "/kitchenimages/" + e.data["Image Id"];
        return (        
      <Link className="user-link" to={link}>
        {e.data["Image Date"]}
      </Link>
    );
  };

  const [columnDefs] = useState([
    { field: "Image Date", flex: 1, cellRenderer: kitchenImageCellRenderer },
    { field: "Submitted By", flex: 2 },
    { field: "Reviewed", flex: 1 },
    { field: "Reviewed Date", flex: 1 },
    { field: "Reviewed By", flex: 2 }
  ]);
  
  useEffect(() => {
    fetchKitchenImages();
  }, []);

  const fetchKitchenImages = useCallback(async () => {
    GET(
    `${API_URL}/api/v1/Kitchen/GetKitchenImageList?kitchenId=` + params.kitchenId,
    {},
    getDataCallback
    );
  }, []);

  const getDataCallback = (response) => {
    var temp = [];
    
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var image = {
            "Image Id": d.id,
            "Kitchen Name": d.kitchenName,
            "Image Date": new Date(d.imageDate).toLocaleDateString(),
            "Submitted By": d.submittedByName,
            "Reviewed": d.reviewed ? "Yes" : "No",
            "Reviewed Date": d.reviewedDate === null ? "" : new Date(d.reviewedDate).toLocaleDateString(),
            "Reviewed By": d.reviewedByName
        };
        temp.push(image);
      });
    }

    setFullGridData(temp);
    applyGridFilters(temp);
  };

  const applyGridFilters = (data) => {
    if (showReviewed.current.checked) {
        setVisibleGridData(data);        
    }
    else {
        const filteredData = data.filter((d) => d["Reviewed"] === "No");
        setVisibleGridData(filteredData);
    }
  }

  return (
    <div className="root-block">
        <Row>
            <Col xs={10}>
            {fullGridData?.length > 0 ? "Production Kitchen: "+ fullGridData[0]["Kitchen Name"] : ""}
            </Col>
            <Col xs={2}>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Show Reviewed"
                    ref={showReviewed}                
                    style={{
                        color: "blue",
                    }}
                    onChange={() => {applyGridFilters(fullGridData)}}
                />
            </Col>
        </Row>
        <Grid
            sortable={true}
            filter={true}
            data={visibleGridData}
            columnDefs={columnDefs}
        />
    </div>
  );
};

export default KitchenImageList;