export function NavigateKeyDown(keyPressed, gridRef) {
    // We only navigate with arrow keys
    if (!keyPressed.startsWith("Arrow")) {
        return;
    }

    // If we're not in edit mode, then don't navigate cells
    const editingCells = gridRef.current.api.getEditingCells();
    if (editingCells.length === 0) {
        return;
    }

    gridRef.current.api.stopEditing();

    const editingCell = editingCells[0];
    const gridColumns = gridRef.current.columnApi.getColumns();
    var targetRowIndex = editingCell.rowIndex;
    var targetColumnIndex = gridColumns.findIndex((x) => x.colId === editingCell.column.colId)

    switch (keyPressed) {
        case "ArrowDown":
            targetRowIndex++;
            if (targetRowIndex >= gridRef.current.api.getDisplayedRowCount()) {
                return;
            }
            break;
        case "ArrowUp":
            targetRowIndex--;
            if (targetRowIndex < 0) {
                return;
            }
            break;
        case "ArrowRight":
            targetColumnIndex++;
            if (targetColumnIndex >= gridColumns.length) {
                return;
            }
            break;
        case "ArrowLeft":
            targetColumnIndex--;
            if (targetColumnIndex < 0) {
                return;
            }
            break;
        default:
            return;            
    }

    gridRef.current.api.startEditingCell({
        rowIndex: targetRowIndex,
        colKey: gridColumns[targetColumnIndex]});
};

export function EnterEditMode(event, gridRef) {
    gridRef.current.api.startEditingCell({
      rowIndex: event.rowIndex,
      colKey: event.column.colId});
}