import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import logo from "../images/logo.png";
import AuthContext from "../store/auth-context";
import "./Header.css";

const Header = () => {
  const authCtx = useContext(AuthContext);
  const name = authCtx.username;
  const isLoggedIn = authCtx.isLoggedIn;
  const navigate = useNavigate();
  const logoutHandler = () => {
    authCtx.logout();
    navigate("/login");
  };

  return (
    <Navbar className="bg-color" variant="dark">
      <Navbar.Brand>
        <Link to="/dashboard">
          <img
            src={logo}
            width="90" //Todo : Need to find a better resolution image which is not streched
            height="40"
            className="d-inline-block align-top"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Text className="site-name">
        Shrink Manager
      </Navbar.Text>
      <Navbar.Text 
        style={{color: "yellow", marginLeft: "150px", fontSize: "175%"}}
        hidden={window.ENVIRONMENT_KEY === "production"}>
        *** {window.ENVIRONMENT_KEY === "test" ? "TEST" : "DEVELOPMENT"} ENVIRONMENT ***
      </Navbar.Text>
      {isLoggedIn && (
        <React.Fragment>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>{name}</Navbar.Text>
          </Navbar.Collapse>
          <Nav>
            <Button
              className="logout-link"
              variant="light"
              onClick={logoutHandler}
            >
              Logout
            </Button>
          </Nav>
        </React.Fragment>
      )}
    </Navbar>
  );
};

export default Header;
