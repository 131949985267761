import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API_URL from "../config";
import { STATUS } from "../constants/Common";
import { ShowToast } from "../services/Toast";
import AuthContext from "../store/auth-context";
import { TOAST_MODE } from "../constants/Common";
import NumericEditor from "../components/CellEditor"
import { ADD_ORDER, EDIT_ORDER } from "../constants/Messages";
import { EnterEditMode, NavigateKeyDown } from "../services/GridHelper"
import { BuildInvoice, EmailPDF, ExportPDF } from "../components/Invoice"
import { DELETE, DisplayResults, GET, POST, PUT } from "../services/httpClient";
import { CHANGES_SAVED, INVALID_KITCHEN_OR_LOCATION, INVOICE_CANCELLED, INVOICE_FIELD_MISSING, ORDER_DELETED, ORDER_DELETION_FAILED } from "../constants/Messages";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./OrderDetails.css";

const MyHeaderComponent = (props) => {
  let link = "/orders/deliverydetails/" + props.id + "/" + props.deliveryId;
  return (
    <div
      style={{
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      <Link className="user-link" to={link}>
        {props.displayName}
      </Link>
    </div>
  );
};

const OrderDetails = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(0); // 1 for create, 2 for edit, 3 for loading data
  const [heading, setHeading] = useState(ADD_ORDER);
  const [orderData, setOrderData] = useState(null);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [kitchens, setKitchens] = useState([]);
  const [locations, setLocations] = useState({});
  const [gridShrinkDays, setGridShrinkDays] = useState();

  const orderKitchen = useRef(-1);
  const orderLocation = useRef();
  const orderstatus = useRef();
  const referenceNumber = useRef();
  const showWeekend = useRef(false);
  const showShrink = useRef(false);
  const [orderNumber, setOrderNumber] = useState();

  // Contact information
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [mainContact, setMainContact] = useState();
  const [mainContactPhone, setMainContactPhone] = useState();
  const [mainContactEmail, setMainContactEmail] = useState();
  const [billingContact, setBillingContact] = useState();
  const [billingContactPhone, setBillingContactPhone] = useState();
  const [billingContactEmail, setBillingContactEmail] = useState();

  // Invoice information
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [processingFee, setProcessingFee] = useState(0.00);
  const processingFeeAcmountControl = useRef();
  const useProcessingFeeControl = useRef(false);
  const [canOpenInvoice, setCanOpenInvoice] = useState(false);
  const [invoiceActionEnabled, setInvoiceActionEnabled] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [invoicePDF, setInvoicePDF] = useState();
  const invoiceEmailSubjectControl = useRef();
  const invoiceEmailBodyControl = useRef();

  //Grid information
  const gridRef = useRef();
  const [gridData, setGridData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);

  // Dialog information
  const [selectedDate, setSelectedDate] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [showEmailInvoiceDialog, setShowEmailInvoiceDialog] = useState(false);
  let emailInvoice = false;
  
  const handleCloseDialogs = () => {
    setShowConfirmationDialog(false);
    setShowContactDialog(false);
    setShowInvoiceDialog(false);
    setShowEmailInvoiceDialog(false);
  };

  const handleShowContactDialog = () => {
    if (validateKitchenAndLocation()) {
      setShowContactDialog(true);
    }
  };

  const handleShowInvoiceDialog = () => {
    if (validateKitchenAndLocation() && canOpenInvoice) {
      setInvoiceNumber(orderData.invoiceNumber);
      setInvoiceDate(orderData.invoiceDate == null ? null : new Date(orderData.invoiceDate));
      setShowInvoiceDialog(true);
      setInvoiceActionEnabled(canBeInvoiced(orderData.invoiceNumber, orderData.invoiceDate));
    }
  };

  const handleShowConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const filterDate = (date) => {
    // Disable all dates except Sundays
    return date.getDay() === 6;
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 200,
    },
  };

  const gridOptions = {
    localeText: {
      noRowsToShow:
        "There is no data existing. Please create a new order or choose copy to copy from the previous week",
      loadingOoo: "Loading data, please wait...",
    },
    rowClassRules: {
      "custom-row-style": "data.length === 0", // apply custom class to rows with no data
    },
  };

  const userCellRenderer = (e) => {
    if (e.data.productName === "Totals") {
      return (<Form.Label>Totals</Form.Label>);
    }

    var userlink = "/products/" + e.data.productId;
    return (
      <Link className="user-link" to={userlink}>
        {e.data.productName}
      </Link>
    );
  };

  const getLocationDataCallback = (response) => {
    var temp = [];
    if (DisplayResults(response, null)) {
      var data = response.result;
      if (data != null) {
        data.forEach((d) => {
          var u = {
            id: d.id,
            name: d.name,
            address: d.address,
            city: d.city,
            state: d.state,
            zip: d.zip,
            mainContactName: d.mainContactName,
            mainContactEmail: d.mainContactEmail,
            mainContactPhone: d.mainContactPhone,
            billingContactName: d.billingContactName,
            billingContactEmail: d.billingContactEmail,
            billingContactPhone: d.billingContactPhone,
          };
          temp.push(u);
        });
      } else {
        var u = {
          id: -1,
          name: "Please select a location",
          address: "",
          city: "",
          state: "",
          zip: "",
          mainContactName: "",
          mainContactEmail: "",
          mainContactPhone: "",
          billingContactName: "",
          billingContactEmail: "",
          billingContactPhone: "",
          kitchenId: -1,
          deliveryInstructions: "",
          requireSignature: true,
          active: true,
          createdDate: "",
        };
        temp.push(u);
      }
    }
    setLocations(temp);
    if (mode === 1) {
      setAddress(temp[0].address);
      setCity(temp[0].city);
      setState(temp[0].state);
      setZip(temp[0].zip);
      setMainContact(temp[0].mainContactName);
      setMainContactEmail(temp[0].mainContactEmail);
      setMainContactPhone(temp[0].mainContactPhone);
      setBillingContact(temp[0].billingContactName);
      setBillingContactEmail(temp[0].billingContactEmail);
      setBillingContactPhone(temp[0].billingContactPhone);
    }
  };

  const fetchLocationHandler = (id) => {
    try {
      GET(
        `${API_URL}/api/v1/Location/GetAllLocations?kitchenId=` + id,
        {},
        getLocationDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getNextSaturday = () => {
    var today = new Date();
    var daysUntilSaturday = 6 - today.getDay();
    if (daysUntilSaturday <= 0) {
      daysUntilSaturday += 7;
    }
    var nextSaturday = new Date(
      today.getTime() + daysUntilSaturday * 24 * 60 * 60 * 1000
    );
    return nextSaturday;
  };

  const convertDateObjectToList = (obj) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const result = {};

    for (const dateStr in obj) {
      const date = new Date(dateStr);
      const dayOfWeek = daysOfWeek[date.getDay()];
      result[dayOfWeek] = obj[dateStr];
    }

    return result;
  };

  function createGridColumn(columnName) {
    return {
      field: columnName,
      editable: (params) => { return !params.node.rowPinned; },
      cellEditor: NumericEditor,
      flex: 1,
      wrapHeaderText: true
    };
  }

  // Function to handle the repetitious creation of shrink columns
  // dayName should be a 3-letter abbreviations, such as "Tue"
  function createShrinkHeaderColumn(orderId, shrinkDays, dayName) {
    return {
      field: `${dayName.toLowerCase()}Shrink`,
      flex: 1,
      editable: (params) => { return !params.node.rowPinned; },
      cellEditor: NumericEditor,
      wrapHeaderText: true,
      headerComponent: MyHeaderComponent,
      headerComponentParams: {
        displayName: `${dayName} Shrink`,
        deliveryId: shrinkDays[dayName],
        id: orderId,
      }
    }
  }

  const setShrinkDaysOnGrid = (
    shrinkDays,
    showWeekends,
    showShrinkDays,
    orderId
  ) => {
    //Logic to dynamically generate the columns
    var colTemp = [];
    colTemp.push({
      field: "productName",
      flex: 2,
      cellRenderer: userCellRenderer,
    });

    if (showWeekends) {
      colTemp.push(createGridColumn("sunOrder"));
      if (showShrinkDays) {
        if (shrinkDays.hasOwnProperty("Sun")) {
          colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Sun"));
        } else {
          colTemp.push(createGridColumn("sunShrink"));
        }
      }
    }

    colTemp.push(createGridColumn("monOrder"));
    if (showShrinkDays) {
      if (shrinkDays.hasOwnProperty("Mon")) {
        colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Mon"));
      } else {
        colTemp.push(createGridColumn("monShrink"));
      }
    }

    colTemp.push(createGridColumn("tueOrder"));
    if (showShrinkDays) {
      if (shrinkDays.hasOwnProperty("Tue")) {
        colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Tue"));
      } else {
        colTemp.push(createGridColumn("tueShrink"));
      }
    }

    colTemp.push(createGridColumn("wedOrder"));
    if (showShrinkDays) {
      if (shrinkDays.hasOwnProperty("Wed")) {
        colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Wed"));
      } else {
        colTemp.push(createGridColumn("wedShrink"));
      }
    }

    colTemp.push(createGridColumn("thuOrder"));
    if (showShrinkDays) {
      if (shrinkDays.hasOwnProperty("Thu")) {
        colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Thu"));
      } else {
        colTemp.push(createGridColumn("thuShrink"));
      }
    }

    colTemp.push(createGridColumn("friOrder"));
    if (showShrinkDays) {
      if (shrinkDays.hasOwnProperty("Fri")) {
        colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Fri"));
      } else {
        colTemp.push(createGridColumn("friShrink"));
      }
    }

    if (showWeekends) {
      colTemp.push(createGridColumn("satOrder"));
      
      if (showShrinkDays) {
        if (shrinkDays.hasOwnProperty("Sat")) {
          colTemp.push(createShrinkHeaderColumn(orderId, shrinkDays, "Sat"));
        } else {
          colTemp.push(createGridColumn("satShrink"));
        }
      }
    }

    setColumnDefs(colTemp);
  };

  const validateKitchenAndLocation = () => {
    var kitchenId = Number(
      orderKitchen.current.selectedOptions[0].attributes["data-keyid"].value
    );
    var locationId = Number(
      orderLocation.current.selectedOptions[0].attributes["data-keyid"].value
    );

    if (kitchenId === -1 || locationId === -1) return false;
    else return true;
  };

  const copyHandler = () => {
    if (validateKitchenAndLocation()) {
      createNewOrderHandler(true);
    } else {
      ShowToast(TOAST_MODE.Error, INVALID_KITCHEN_OR_LOCATION);
    }
  };

  const generateHandler = () => {
    if (validateKitchenAndLocation()) {
      createNewOrderHandler(false);
    } else {
      ShowToast(TOAST_MODE.Error, INVALID_KITCHEN_OR_LOCATION);
    }
  };

  // this is a duplicate method because when the data is fetched for the first time we need to
  // fetch the data and set the kitchen and location
  // If the location or kitchen changes then we again need to fetch the details but in this case
  // the dropdowns won't be set
  // If the duplicate is not created then we enter a cycle where we set the dropdown which triggers the fetch event
  // and the fetch event resets the dropdowns again
  const fetchOrderDetailsOnLoad = (orderId, locationId, weekEndingDate) => {
    try {
      GET(
        `${API_URL}` +
          "/api/v1/Order/GetOrderDetails?locationId=" +
          locationId +
          "&orderDate=" +
          weekEndingDate,
        {},
        (response) => {
          setFirstTimeLoad(false);
          
          if (response.result != null) {
            var details = response.result;

            const shrinkDays = convertDateObjectToList(
              details.deliveryIds
            );

            setGridData(details.productDetail);
            setOrderData(details);
            setSelectedDate(new Date(details.endDate));
            setCanOpenInvoice(["Counted", "Invoiced"].includes(details.status));    
            setGridShrinkDays(shrinkDays);
            setShrinkDaysOnGrid(
              shrinkDays,
              details.hasWeekendData,
              details.hasShrinkData,
              details.id
            );
            showWeekend.current.checked = details.hasWeekendData;
            showShrink.current.checked = details.hasShrinkData;

            setAddress(details.customerLocation.address);
            setCity(details.customerLocation.city);
            setState(details.customerLocation.state);
            setZip(details.customerLocation.zip);
            setMainContact(details.customerLocation.mainContactName);
            setMainContactEmail(details.customerLocation.mainContactEmail);
            setMainContactPhone(details.customerLocation.mainContactPhone);
            setBillingContact(details.customerLocation.billingContactName);
            setBillingContactEmail(
              details.customerLocation.billingContactEmail
            );
            setBillingContactPhone(
              details.customerLocation.billingContactPhone
            );

            setOrderNumber(details.orderNumber);
            referenceNumber.current.value = details.referenceNumber ?? "";
            setProcessingFee(details.processingFee);
          } else {
            setGridData([]);
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  // The effect was used apart from the generation method itself so
  // the generation can be called on cellUpdate in the grid as well
  useEffect(() => {
    generateRowTotals();
  }, [columnDefs]);

  function generateRowTotals() {
    if (gridApi == null) {
      return;
    }

    // generate a row with null values to hold the totals
    let totalsRow = {};
    columnDefs.forEach(column => {
      totalsRow[column.field] = 0; // Initialize the total cell to zero

      if (column.field === "productName") {
        totalsRow[column.field] = "Totals"; // No total in the row label
      }
      else {
        gridApi.forEachNode((rowNode, index) => {
          if (Number.isInteger(rowNode.data[column.field])) {
              totalsRow[column.field] += Number(rowNode.data[column.field]);
          } else {
            rowNode.setDataValue(column.field,0);
          }
        })
      }
    });

    gridApi.setPinnedBottomRowData([totalsRow]);
  };

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: "bold", backgroundColor: "azure" };
    }
  }, []);

  const locationChangeHandler = () => {
    var selectedLocation = locations[0];
    if (orderLocation.current.selectedOptions.length > 0) {
      var selectedId =
        orderLocation.current.selectedOptions[0].attributes["data-keyid"].value;
      selectedLocation = locations.find((loc) => loc.id === selectedId);
    }

    setAddress(selectedLocation.address);
    setCity(selectedLocation.city);
    setState(selectedLocation.state);
    setZip(selectedLocation.zip);
    setMainContact(selectedLocation.mainContactName);
    setMainContactEmail(selectedLocation.mainContactEmail);
    setMainContactPhone(selectedLocation.mainContactPhone);
    setBillingContact(selectedLocation.billingContactName);
    setBillingContactEmail(selectedLocation.billingContactEmail);
    setBillingContactPhone(selectedLocation.billingContactPhone);
  };

  const getKitchenDataCallback = (response) => {
    var temp = [];
    if (DisplayResults(response, null)) {
      var data = response.result;
      data.forEach((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }

    temp.push({
      id: -1,
      name: "Please select a kitchen",
    });
    setKitchens(temp);

    if (mode === 2) {
      fetchLocationHandler(orderData.kitchenId);
    } else {
      //fetchLocationHandler(temp[0].id);
      //In case of create mode, there is no need to fetch the locations. So directly calling the callback function
      //and setting the first value as select. This way don't have to create another function to set all these values
      response = {
        isSuccess: true,
        result: [
          {
            id: -1,
            name: "Please select a location",
            address: "",
            city: "",
            state: "",
            zip: "",
            contact: "",
            phone: "",
            email: "",
            kitchenId: -1,
            deliveryInstructions: "",
            requireSignature: true,
            active: true,
            createdDate: "",
          },
        ],
      };
      setSelectedDate(new Date(getNextSaturday()));
      getLocationDataCallback(response);
    }
  };

  const fetchKitchenHandler = () => {
    try {
      GET(
        `${API_URL}/api/v1/Kitchen/GetAllKitchens`,
        {},
        getKitchenDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  };

  const kitchenChangeHandler = () => {
    fetchLocationHandler(orderKitchen.current.value);
  };

  const gridSwitchesHandler = () => {
        setShrinkDaysOnGrid(
      gridShrinkDays,
      showWeekend.current.checked,
      showShrink.current.checked,
      orderData.id
    );
  };

  const setKitchen = (itemId) => {
    if (mode === 2 && orderData) {
      return orderData.kitchenId === itemId;
    } else if (mode === 1) {
      return itemId === -1;
    }
  };

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.id}
          selected={setKitchen(item.id)}
        >
          {item.name}
        </option>
      );
    });

  const locationList =
    locations.length > 0 &&
    locations.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.id}
          selected={
            mode === 2 && orderData && orderData.customerLocation.id === item.id
          }
        >
          {item.name}
        </option>
      );
    });

  const statusList =
    STATUS.length > 0 &&
    STATUS.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.name}
          hidden={item.name === "Invoiced" && orderData && orderData.status !== "Invoiced"}
          selected={
            mode === 2 &&
            orderData &&
            orderData.status.toLowerCase() === item.name.toLowerCase()
          }
        >
          {item.name}
        </option>
      );
    });

  // Determines if the invoice status and the invoice fields are valid.
  // Returns true if they are, false if they aren't and NULL if we must clear them
  const confirmInvoiceStatus = (newStatus) => {  
    // If ths status is Invoiced, but the invoice fields are empty, reject
    if (newStatus === "Invoiced" && !canBeInvoiced(invoiceNumber, invoiceDate)) {
      ShowToast(TOAST_MODE.WARNING, INVOICE_FIELD_MISSING);
      orderstatus.current.options.selectedIndex = 2; // Counted option
      return false;
    }

        // If the order was 'un-invoiced', or if invoice info was added to a counted order, we need to clear the fields
    if (newStatus !== "Invoiced" &&
      (invoiceNumber !== null || invoiceDate !== null)) {
      ShowToast(TOAST_MODE.INFO, INVOICE_CANCELLED);
      setInvoiceNumber(null);
      setInvoiceDate(null);
      return null;
    }

    return true;
  }
  
  const saveHandler = (invoiceAfterSave) => {
    gridRef.current.api.stopEditing();
    generateRowTotals();

    const newStatus = orderstatus.current.selectedOptions[0].attributes["data-keyid"].value;
    const invoiceResult = confirmInvoiceStatus(newStatus)
    if (invoiceResult === false) { // Null or True should pass through
      return;
    }
    
    orderData.referenceNumber = referenceNumber.current.value;
    orderData.processingFee = processingFee;
    orderData.invoiceNumber = invoiceResult == null ? null : invoiceNumber;
    orderData.invoiceDate = invoiceResult == null ? null : invoiceDate;
    orderData.status = newStatus;
    orderData.productDetail = JSON.parse(JSON.stringify(gridData));

    PUT(
      `${API_URL}/api/v1/Order/UpdateOrderDetails`,
      orderData,
      (response) => {
        if (DisplayResults(response, CHANGES_SAVED)) {
          gridApi.redrawRows();
          setCanOpenInvoice(["Counted", "Invoiced"].includes(orderData.status)); 

          if (invoiceAfterSave) {
            BuildInvoice(orderData.id, setInvoiceData, setInvoicePDF);
          }
        }        
      }      
    );
  };

  const createNewOrderCallback = (response) => {
    var d = response.result;
    if (DisplayResults(response)) {
      var link =
        "/orders/" +
        d.id +
        "/" +
        d.locationId +
        "/" +
        new Date(d.endDate).toISOString().slice(0, 10);
      navigate(link);

      setMode(2); // Since new order has been created, we would now be in edit mode
    }
  };

  const createNewOrderHandler = (isCopy) => {
    POST(
      `${API_URL}/api/v1/Order/CreateWeeklyOrder`,
      {
        LocationId:
          orderLocation.current.selectedOptions[0].attributes["data-keyid"]
            .value,
        OrderDate: selectedDate.toLocaleDateString(),
        CopyPriorOrder: isCopy,
      },
      createNewOrderCallback
    );
  };

  const deleteHandler = () => {
    var id = orderData.id;
    try {
      DELETE(
        `${API_URL}/api/v1/Order/RemoveOrder?orderId=` + id,
        {},
        (response) => {
          handleCloseDialogs();
          if (DisplayResults(response, ORDER_DELETED, ORDER_DELETION_FAILED)) {
            navigate("/orders");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (orderData != null) {
      fetchKitchenHandler();
    }
  }, [orderData]);

  useEffect(() => {
    // The main purpose why the second useEffect has been added is the unpredictability of the setState.
    // If the setstate was getting done before fetchOrderDetails get called then the mode would have been
    //set correctly. But sometimes it does and sometimes it doesn't and React setState is asynchronous
    // to make sure everytime the correct order is followed we created the second useEffect and it's required
    // to be called only once
    if (mode !== 0 && firstTimeLoad) {
      if (mode === 2) {
        fetchOrderDetailsOnLoad(
          params.orderId,
          params.locationId,
          params.weekEndingDate
        );
      } else {
        fetchKitchenHandler();
      }
    }
  }, [mode]);

  useEffect(() => {
    if (params.orderId) {
      setMode(2); // first time load and we have edit params so Edit mode
      setHeading(EDIT_ORDER);
      setOrderNumber(params.orderId);
      showWeekend.current.checked = false;
      showShrink.current.checked = false;
    } else {
      setMode(1); // no params present means Create
      showWeekend.current.checked = false;
      showShrink.current.checked = false;
    }
  }, []);

  const canBeInvoiced = (invNum, invDate) => {
    if (invNum == null || invDate == null) {
      return false;
    }

    if (orderData == null || orderData.endDate == null) {
      return false;
    }

    // Invoice# must be non-whitespace
    if (invNum.toString().trim().length === 0) {
      return false;
    }

    // Invoice Date must be on or after the order's week end date
    if (new Date(invDate) < new Date(orderData.endDate)) {
      return false;
    }

    // User must have rights to invoices
    if (!authCtx.writeRights.includes("GenerateInvoices")) {
      return false;
    }

    return true;
  };

  const handleInvoiceNumberChanged = (event) => {
    // Reset the invoice so it must be regenerated
    setInvoiceData(null);
    setInvoicePDF(null);

    setInvoiceNumber(event.target.value);
    setInvoiceActionEnabled(canBeInvoiced(event.target.value, invoiceDate));
  };

  const handleInvoiceDateChanged = (newDate) => {
    // Reset the invoice so it must be regenerated
    setInvoiceData(null);
    setInvoicePDF(null);

    setInvoiceDate(newDate)
    setInvoiceActionEnabled(canBeInvoiced(invoiceNumber, newDate));
  };

  const generateInvoice = () => {
    // If any invoice-related information was changed (status/number/date), we need to save first.
    if (orderData.processingFee !== processingFee ||
      orderstatus.current.options.selectedIndex !== 3 ||
      orderData.invoiceNumber !== invoiceNumber ||
      new Date(orderData.invoiceDate).toString() !== new Date(invoiceDate).toString()) {
        orderstatus.current.options.selectedIndex = 3; // Invoice option
        saveHandler(true);
    }
    else {
      BuildInvoice(orderData.id, setInvoiceData, setInvoicePDF);
    }
  }

  const sendInvoice = () => {
    // This has been moved to its own dialog, so window.cofirm won't work and becomes redudnant anyway
    EmailPDF(invoiceData, invoicePDF, invoiceEmailSubjectControl.current.value, invoiceEmailBodyControl.current.value);
    handleCloseDialogs();
  }

  const exportInvoice = () => {
    ExportPDF(invoiceData, invoicePDF);
    handleCloseDialogs();
  }

  return (
    <div className="root-block">
      <h2>{heading}</h2>

      <Row>
        <Col xs={4}>
          <Row className="mg-bottom-10">
            <Col xs={4} className="label">
              Kitchen
            </Col>
            <Col xs={8}>
              <Form.Select
                aria-label="Default label"
                ref={orderKitchen}
                className="label"
                onChange={kitchenChangeHandler}
                disabled={mode === 2}
              >
                {kitchenList}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mg-bottom-10">
            <Col xs={4} className="label">
              Location
            </Col>
            <Col xs={8}>
              <Form.Select
                aria-label="Default label"
                ref={orderLocation}
                onChange={locationChangeHandler}
                disabled={mode === 2}
              >
                {locationList}
              </Form.Select>
            </Col>
          </Row>
          <Row></Row>
        </Col>
        <Col xs={4}>
          <Row className="mg-bottom-10">
            <Col xs={4} className="label">Week Ending</Col>
            <Col xs={8}>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                filterDate={filterDate}
                placeholderText="Select a Saturday"
                dateFormat="MM/dd/yyyy"
                className="date-picker"
                disabled={mode === 2}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <span
                onClick={handleShowContactDialog}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {" "}
                See contact details{" "}
              </span>
            </Col>            
            {canOpenInvoice && authCtx.readRights.includes("GenerateInvoices") && <Col xs={6}>
              <span
                onClick={handleShowInvoiceDialog}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {" "}
                See invoice details{" "}
              </span>
            </Col> }
          </Row>
        </Col>
        <Col xs={4}>
          {mode === 2 && (
            <Row className="mg-bottom-10">
              <Col xs={5}>Order #</Col>
              <Col xs={7}>{orderNumber}</Col>
            </Row>
          )}
          <Row>
          <Row className="mg-bottom-10">
              <Col xs={5}>Reference #</Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  placeholder="Enter reference#"
                  maxLength={100}
                  ref={referenceNumber} />
              </Col>
          </Row>
          <Row className="mg-bottom-10">
            <Col xs={5}>
              Status
            </Col>
            <Col xs={7}>
              <Form.Select
                disabled={mode === 1 || mode === 3}
                ref={orderstatus}
              >
                {statusList}
              </Form.Select>
            </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={8}></Col>
        <Col xs={4} style={{ textAlign: "right" }}>
          {(mode === 1 || mode === 3) && (
            <Button
              variant="primary"
              onClick={copyHandler}
              style={{ width: "95px", marginRight: "15px" }}
            >
              Copy
            </Button>
          )}
          {(mode === 1 || mode === 3) && (
            <Button variant="primary" onClick={generateHandler}>
              Generate
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={2}>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Show Weekends"
            ref={showWeekend}
            onChange={gridSwitchesHandler}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Show Shrink"
            ref={showShrink}
            onChange={gridSwitchesHandler}
          />
        </Col>
      </Row>
      <div className="ag-theme-alpine order-grid">
        <AgGridReact
          ref={gridRef}
          gridOptions={gridOptions}
          rowSelection="single"
          defaultColDef={defaultColDef}
          getRowStyle={getRowStyle}
          rowData={gridData}
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          onGridReady={(params) => {setGridApi(params.api)}}
          onCellEditingStopped={generateRowTotals}
          onCellKeyDown={(e) => {
            NavigateKeyDown(e.event.key.toString(), gridRef)
          }}
          onCellClicked={(e) => { 
            EnterEditMode(e, gridRef) 
          }}
        ></AgGridReact>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          {mode === 2 && (
            <Col xs={1}>
              <Button 
                className="button-standard"
                variant="outline-danger"
                onClick={handleShowConfirmationDialog}
                disabled={!authCtx.writeRights.includes("OrderList")}>
                Delete
              </Button>
            </Col>
          )}
          <Col xs={9}></Col>
          <Col xs={2}>
            {mode === 2 && (
              <Row>
                <Col xs={6}>
                  <Button 
                    className="button-standard"
                    variant="outline-dark">
                    <Link to="/orders">Close</Link>
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button 
                    className="button-standard"
                    variant="primary"
                    type="button"
                    onClick={() => { saveHandler(false)}}
                    disabled={!authCtx.writeRights.includes("OrderList")}>
                    Save
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Modal show={showConfirmationDialog} onHide={handleCloseDialogs}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the order?</Modal.Body>
        <Modal.Footer>
          <Button 
            className="button-standard"
            variant="outline-dark"
            onClick={handleCloseDialogs}>
            No
          </Button>
          <Button 
            className="button-standard"
            variant="danger"
            onClick={deleteHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showContactDialog} onHide={handleCloseDialogs}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4}>Address:</Col>
            <Col xs={8}>
              <div>{address}</div>
              <div>
                {city != null
                  ? city + (state != null ? ", " + state : "")
                  : state}
              </div>
              <div>{zip}</div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>Main Contact : </Col>
            <Col xs={8}>
              {mainContact}
              <br /> {mainContactPhone}
              <br /> {mainContactEmail}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>Billing Contact: </Col>
            <Col xs={8}>
              {billingContact}
              <br /> {billingContactPhone}
              <br /> {billingContactEmail}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button-standard"
            variant="outline-dark"
            onClick={handleCloseDialogs}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showInvoiceDialog} onHide={handleCloseDialogs} onShow={() => {
        // These controls can't be initialized until the popup is shown and the ref is active
        useProcessingFeeControl.current.checked = processingFee > 0;
        processingFeeAcmountControl.current.disabled = !useProcessingFeeControl.current.checked;
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Invoice Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4} className="label-standard">
              Invoice #:</Col>
            <Col xs={8}>
              <Form.Control
              className="label-standard"
              type="text"
              value={invoiceNumber}
              placeholder="Enter invoice#"
              maxLength={50}
              onChange={handleInvoiceNumberChanged} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>Invoice date: </Col>
            <Col xs={8}>
              <DatePicker
                selected={invoiceDate}
                onChange={handleInvoiceDateChanged}
                placeholderText="Select a Date"
                dateFormat="MM/dd/yyyy"
                className="date-picker"
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>Terms:</Col>
            <Col xs={8}>
              {orderData == null ? "" : orderData.displayTerms}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>Processing Fee:</Col>
            <Col xs={1}>
              <Form.Check
                  type="checkbox"
                  ref={useProcessingFeeControl}
                  onClick={() => {
                    setProcessingFee(useProcessingFeeControl.current.checked ? orderData.processingFee : 0);
                    processingFeeAcmountControl.current.disabled = !useProcessingFeeControl.current.checked;
                  }}
                />
            </Col>
            <Col xs={4}>
              <Form.Control
                className="label-standard"
                type="number"
                placeholder="0.00"
                value={processingFee}
                step={.01} 
                onChange={(e) => {setProcessingFee(e.target.value)}}
                ref={processingFeeAcmountControl}/>
            </Col>
            <Col xs={3}>
              ({(processingFee * 100).toFixed(2)}%)
            </Col>
          </Row>
          <br />
          <Row>
            {invoiceData?.repeatOrders > 0 && (
              <Form.Label style={{color: "red"}}>
                Warning: The invoice number you entered has been used on {invoiceData.repeatOrders} other orders from different weeks. Please confirm this was intentional.
              </Form.Label>
            )}
            {invoiceData?.combinedOrders > 0 && (
              <Form.Label style={{color: "red"}}>
                Warning: This invoice combines {invoiceData.combinedOrders + 1} orders. If this was not intended, check the invoice number and ensure it is unique.
              </Form.Label>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            className="button-standard"
            variant="outline-dark"
            onClick={handleCloseDialogs}>
            Close
          </Button>
          <Button
            className="button-standard"
            variant="outline-primary"
            disabled={!invoiceActionEnabled || invoiceData == null || processingFee > 0}
            onClick={() => {
              setShowInvoiceDialog(false);
              setShowEmailInvoiceDialog(true);
              }}>
            Email
          </Button>
          <Button
            className="button-standard"
            variant="outline-primary"
            disabled={!invoiceActionEnabled || invoiceData == null}
            onClick={() => exportInvoice()}>
            Export
          </Button>
          <Button
            variant="primary"
            disabled={!invoiceActionEnabled}
            onClick={() => { generateInvoice() }}>
            Generate Invoice
          </Button>
        </Modal.Footer>
      </Modal>     
      <Modal show={showEmailInvoiceDialog} onHide={handleCloseDialogs}>
        <Modal.Header closeButton>
          <Modal.Title>Send Invoice Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
            <Col xs={2} className="label-standard">
              Recipient:</Col>
            <Col xs={10}>
              <Form.Control
              className="label-standard"
              type="text"
              disabled={true}
              value={invoiceData?.email} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={2} className="label-standard">
              Subject:</Col>
            <Col xs={10}>
              <Form.Control
              className="label-standard"
              type="text"
              defaultValue={invoiceData?.defaultEmailSubject}
              ref={invoiceEmailSubjectControl}
              placeholder="Enter email subject" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={2} className="label-standard">
              Greeting:</Col>
            <Col xs={10}>
              <Form.Control
              className="label-standard"
              as="textarea"
              disabled={true}
              value={invoiceData?.defaultEmailGreeting}/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={2} className="label-standard">
              Message:</Col>
            <Col xs={10}>
              <Form.Control
              className="label-standard"
              as="textarea"
              rows={5}
              defaultValue={invoiceData?.defaultEmailBody}
              ref={invoiceEmailBodyControl}
              placeholder="Enter email message" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={2} className="label-standard">
              Footer:</Col>
            <Col xs={10}>
              <Form.Control
              className="label-standard"
              as="textarea"
              disabled={true}
              value={invoiceData?.defaultEmailFooter} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            className="button-standard"
            variant="outline-dark"
            onClick={handleCloseDialogs}>
            Close
          </Button>
          <Button
            className="button-standard"
            variant="outline-primary"
            disabled={!invoiceActionEnabled}
            onClick={() => sendInvoice()}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrderDetails;