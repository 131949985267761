import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import API_URL from "../config";
import { UNSUBSCRIBE_SUCCESS } from "../constants/Messages";
import { DisplayResults, GET } from "../services/httpClient";

const Unsubscribe = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  useEffect(() => {    
    const searchParams = new URLSearchParams(location.search);
    const stopCode = searchParams.get('stopCode');
    unsubscribe(stopCode);
  }, []);

  const unsubscribe = (stopCode) => {
    try {
        GET(
            `${API_URL}` + "/api/v1/Location/StopConfirmationEmail?stopCode=" + stopCode,
          {},
          unsubscribeCallback
        );
      } catch (err) {
        debugger
        console.log(err);
      }
  };
  
  const unsubscribeCallback = (response) => {
    setIsLoading(false);

    if (DisplayResults(response, UNSUBSCRIBE_SUCCESS)) {
        setUpdateSuccessful(true);
      } else {
        setUpdateSuccessful(false);
    }
  };

  return (
    <div style={{ margin: "auto", marginTop: '100px', width: '80%' }} >
      {isLoading && (<h4>        
      Please wait while settings are updated.
        <Spinner
            as="span"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
            style={{ marginLeft: "20px" }}
        />
        </h4>)}
        {!isLoading && (
        <h4> {updateSuccessful ?
            'Delivery confirmation emails will no longer be sent. ' :
            'An error occurred stopping the delivery confirmation emails. Please check the link and try again or contact the administrator. ' } 
            You can close this page.</h4>
        )}
    </div>
  );
};

export default Unsubscribe;