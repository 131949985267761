import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import API_URL from "../config";
import Grid from "../components/Grid";
import AuthContext from "../store/auth-context";
import { DisplayResults, GET } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const ProductList = () => {
  const authCtx = useContext(AuthContext);
  const [gridData, setGridData] = useState(null);

  const productCellRenderer = (e) => {
    var userlink = "/products/" + e.data.Id;
    return (
      <Link className="user-link" to={userlink}>
        {e.data.Product}
      </Link>
    );
  };

  const [columnDefs] = useState([
    { field: "Product", flex: 2, cellRenderer: productCellRenderer },
    { field: "Type", flex: 1 },
    { field: "Sequence", flex: 1 },
    { field: "Barcode", flex: 1 },
  ]);

  const getDataCallback = (response) => {
    var temp = [];
    
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {  
          Id: d.id,
          Product: d.name,
          Type: d.productType == 1 ? "Sushi" : "Side",
          Sequence: d.sequence,
          Barcode: d.barcode,
        };
        temp.push(u);
      });
    }

    setGridData(temp);
  };

  const fetchProducts = useCallback(async () => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Product/GetActiveProducts",
        {},
        getDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="root-block">      
      {authCtx.writeRights.includes("ProductList") &&
        <Row style={{ float: "right" }}>
          <Link className="user-link" to="/products/add">
            + Add Product
          </Link>
        </Row>
      }
      <Grid
        sortable={true}
        filter={true}
        data={gridData}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default ProductList;
