import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import API_URL from "../config";
import { ShowToast } from "../services/Toast";
import AuthContext from "../store/auth-context";
import { TOAST_MODE } from "../constants/Common";
import { DisplayResults, POST } from "../services/httpClient";
import { FORGOT_PASSWORD_EMAIL, INCORRECT_USERNAME_PASSWORD, ISSUE_PASSWORD_RESET, PASSWORD_RESET } from "../constants/Messages";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const forgotPasswordEmailInputRef = useRef();
  const newPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  var displayMode = 1;
  var code = searchParams.get("ResetCode");
  if (code) {
    displayMode = 3; // Reset Password
  }

  const [mode, setDisplayMode] = useState(displayMode);

  const authCtx = useContext(AuthContext);

  const userLoggedIn = (response) => {
    setIsLoading(false);
    
    if (DisplayResults(response, null, INCORRECT_USERNAME_PASSWORD)) {
      navigate("/dashboard");
      authCtx.login(response.result);
    }
  };

  const forgotPasswordCallback = (data) => {
    setIsLoading(false);
    if (data.isSuccess == true) {
      ShowToast(TOAST_MODE.SUCCESS, FORGOT_PASSWORD_EMAIL);
    } else {
      ShowToast(TOAST_MODE.ERROR, ISSUE_PASSWORD_RESET);
    }
  };
  
  const resetPasswordCallback = (data) => {
    if (data && data.result && data.isSuccess == true) {
      ShowToast(TOAST_MODE.SUCCESS, PASSWORD_RESET);
      setForgotPasswordMode(1);
    } else {
      ShowToast(TOAST_MODE.ERROR, ISSUE_PASSWORD_RESET);
    }
  };

  const login = (event) => {
    setIsLoading(true);
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    //Add validation
    POST(
      `${API_URL}` + "/api/v1/UserAuth/Login",
      {
        userName: enteredEmail,
        password: enteredPassword,
        isWebsite: true,
      },
      userLoggedIn
    );
  };

  const forgotPassword = () => {
    setIsLoading(true);
    const email = forgotPasswordEmailInputRef.current.value;
    POST(
      `${API_URL}` + "/api/v1/UserAuth/ForgotPassword?email=" + email,
      {},
      forgotPasswordCallback
    );
  };

  const resetPassword = () => {
    const password = newPasswordInputRef.current.value;
    const confirmPassword = confirmPasswordInputRef.current.value;
    if (password == confirmPassword) {
      POST(
        `${API_URL}` + "/api/v1/UserAuth/ResetPassword",
        {
          Code: code,
          password: password,
        },
        resetPasswordCallback
      );
    }
  };

  const setForgotPasswordMode = (value) => {
    setDisplayMode(value);
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == true && localStorage.getItem("token") != null) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="Auth-form-container">
      {mode == 1 && (
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                ref={emailInputRef}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                ref={passwordInputRef}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="button" onClick={login} className="btn btn-primary">
                Submit
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </button>
            </div>
            <p className="forgot-password text-right mt-2">
              <span className="link" onClick={() => setForgotPasswordMode(2)}>
                Forgot password?
              </span>
            </p>
          </div>
        </form>
      )}
      {mode == 2 && (
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Forgot Password</h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                ref={forgotPasswordEmailInputRef}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                type="button"
                onClick={forgotPassword}
                className="btn btn-primary"
              >
                Submit
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </button>
            </div>
            <p className="forgot-password text-right mt-2">
              <span className="link" onClick={() => setForgotPasswordMode(1)}>
                Back to Login
              </span>
            </p>
          </div>
        </form>
      )}
      {mode == 3 && (
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Reset Password</h3>
            <div className="form-group mt-3">
              <label>Enter new password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                ref={newPasswordInputRef}
              />
            </div>
            <div className="form-group mt-3">
              <label>Confirm password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                ref={confirmPasswordInputRef}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                type="button"
                onClick={resetPassword}
                className="btn btn-primary"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
